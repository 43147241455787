@import "../../variable.scss";

.learnHow_hero {
    display: flex;
    align-items: center;
    min-height: 635px;
    background-image: linear-gradient(90deg, #19486A 0%, #030914 100%);

    @include breakpoint(ipadLandsacpe) {
        min-height: 565px;
    }

    @include breakpoint(ipad) {
        min-height: 1px;
    }

    .gap_p {
        @include breakpoint(mobile) {
            --gap_y: 40px;
        }
    }

    :is(.left_s, .right_s) {
        width: 50%;

        @include breakpoint(mobile) {
            width: 100%;
            text-align: center;
        }
    }

    .left_s {
        p {
            font-size: 24px;
            font-weight: 500;
            line-height: 1.3;
            text-transform: uppercase;

            @include breakpoint(desktop) {
                font-size: 22px;
            }

            @include breakpoint(ipadLandsacpe) {
                font-size: 20px;
            }

            @include breakpoint(ipad) {
                font-size: 18px;
            }

            @include breakpoint(mobile) {
                font-size: 16px;
            }
        }
    }

    .right_s {
        display: inline-flex;
        justify-content: center;
        text-align: center;

        figure {
            display: inline-flex;

            @include breakpoint(mobile) {
                max-width: 400px;
            }
        }
    }
}

.learnHow_process {
    .processBox {
        &_single {
            padding: 30px;
            width: calc(100% / 3 - var(--gap_y));
            box-shadow: 0px 2px 56px #0000001A;
            border: 2px solid transparent;
            transition: $transition;
            background: $c_white;
            border-radius: 20px;

            @include breakpoint(ipadLandsacpe) {
                padding: 20px;
                border-radius: 10px;
            }

            @include breakpoint(ipad) {
                width: calc(100% / 2 - var(--gap_y));
            }

            @include breakpoint(mobile) {
                padding: 20px 15px;
                border-radius: 5px;
            }

            @include breakpoint(mobileSmall) {
                width: calc(100% / 1 - var(--gap_y));
            }

            figure {
                display: inline-flex;

                img {
                    height: 80px;

                    @include breakpoint(desktop) {
                        height: 70px;
                    }

                    @include breakpoint(mobile) {
                        height: 60px;
                    }
                }
            }

            h3 {
                margin: 15px 0 20px;

                @include breakpoint(desktop) {
                    margin: 10px 0 15px;
                }

                @include breakpoint(mobile) {
                    margin: 10px 0;
                }
            }

            &:hover {
                border-color: $c_primary;
                transform: translateY(-5px);
            }
        }
    }
}

.learnHow_video {
    figure {
        height: 550px;
        border-radius: 30px;
        overflow: hidden;
        position: relative;

        @include breakpoint(desktop) {
            height: 450px;
        }

        @include breakpoint(ipadLandsacpe) {
            height: 400px;
        }

        @include breakpoint(ipad) {
            height: 350px;
        }

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: $c_secondary;
            opacity: 0.6;
            z-index: 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        a {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;

            img {
                width: 118px;
                height: 118px;

                @include breakpoint(desktop) {
                    width: 100px;
                    height: 100px;
                }

                @include breakpoint(ipadLandsacpe) {
                    width: 80px;
                    height: 80px;
                }

                @include breakpoint(ipad) {
                    width: 60px;
                    height: 60px;
                }
            }
        }
    }

    iframe {
        width: 100%;
        height: auto;
        border-radius: 10px;
        aspect-ratio: 16/9;
    }
}