.action_btn {
  display: flex;
  align-items: center;
  gap: 10px;

  li {
    margin: 0 !important;

    button {
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        color: #fff;
      }

      &:has(svg[data-testid="VisibilityIcon"]), 
      &:has(svg[data-testid="DoneIcon"]){
        background: #56BB6D;
      }

      &:has(svg[data-testid="EditIcon"]) {
        background: #081630;
      }

      &:has(svg[data-testid="DeleteIcon"]),
      &:has(svg[data-testid="CloseIcon"]) {
        background: red;
      }
    }
  }
}