@import "../../variable.scss";

.page_home {
  .home_blogs_sc {
    &.u_spc {
      padding-top: 0;
    }
  }
}

.hero {
  &_home_sc {
    &.uhb_spc {
      @include breakpoint(ipadLandsacpe) {
        padding-top: 40px;
      }

      @include breakpoint(mobile) {
        padding-bottom: 10px;
      }
    }

    .gap_p {
      @include breakpoint(ipadLandsacpe) {
        --gap_y: 50px;
      }

      @include breakpoint(mobile) {
        --gap_y: 40px;
      }
    }

    :is(.left_s, .right_s) {
      width: 50%;
    }

    .left_s {
      @include breakpoint(ipadLandsacpe) {
        width: 90%;
        text-align: center;
        margin-inline: auto;
      }

      @include breakpoint(mobileSmall) {
        width: 100%;
      }

      h1 {
        margin-bottom: 40px;

        @include breakpoint(desktop) {
          margin-bottom: 30px;
        }

        @include breakpoint(ipadLandsacpe) {
          margin-bottom: 25px;
        }

        @include breakpoint(mobile) {
          margin-bottom: 20px;
        }

        span {
          color: $c_primary;
        }
      }

      .btn_flex {
        @include breakpoint(ipadLandsacpe) {
          justify-content: center;
        }
      }
    }

    .right_s {
      @include breakpoint(ipadLandsacpe) {
        width: 100%;
      }

      figure {
        display: flex;
        justify-content: center;

        @include breakpoint(desktop) {
          max-width: 100%;
          margin: 0 0 0 auto;
        }

        @include breakpoint(ipadLandsacpe) {
          max-width: 550px;
          margin: 0 auto;
        }

        @include breakpoint(ipad) {
          max-width: 450px;
        }

        img {
          aspect-ratio: 1/0.8;
          object-fit: cover;
        }
      }
    }
  }

  &_boxes {
    .gap_p {
      --gap_y: 40px;
      justify-content: center;
    }

    .hero_box_single {
      width: 50%;
      text-align: center;

      figure {
        width: 100%;
        max-width: 183px;
        aspect-ratio: 1 / 1;
        display: inline-flex;
        margin-bottom: 20px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      h2 {
        font-weight: 900;
        margin-bottom: 5px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  &_swiper {
    &.swiper {
      padding-bottom: 40px;

      .swiper-pagination {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        &-bullet {
          width: 10px;
          height: 10px;
          margin: 0 !important;

          &-active {
            background-color: $c_primary;
          }
        }
      }
    }
  }
}

.home_steps_sc {
  .step_box_single {
    width: calc(100% / 3 - var(--gap_y));
    padding: 40px;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #dbdbdb;
    transition: $transition;

    @include breakpoint(desktop) {
      padding: 30px;
      border-radius: 5px;
    }

    @include breakpoint(ipad) {
      width: calc(100% / 1 - var(--gap_y));
    }

    @include breakpoint(mobile) {
      padding: 20px;
    }

    figure {
      width: 100%;
      max-width: 151px;
      aspect-ratio: 1 / 1;
      display: inline-flex;
      margin-bottom: 30px;
      transition: $transition;
      border-radius: 50%;
      overflow: hidden;

      @include breakpoint(desktop) {
        max-width: 130px;
        margin-bottom: 20px;
      }

      @include breakpoint(mobile) {
        max-width: 100px;
        margin-bottom: 15px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    h2 {
      margin-bottom: 15px;

      @include breakpoint(mobile) {
        margin-bottom: 10px;
      }
    }

    p {
      font-weight: 500;
    }

    .btn_rounded {
      // margin-right: 25px;
    }

    &:hover {
      border-color: $c_primary;
      transform: scale(1.04);
      box-shadow: $shadow;
    }
  }
}

.home_learn_sc {
  .gap_p {
    @include breakpoint(mobile) {
      --gap_y: 40px;
    }
  }

  :is(.left_s, .right_s) {
    width: 50%;

    @include breakpoint(mobile) {
      width: 100%;
      text-align: center;
    }
  }

  .left_s {
    p {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
      text-transform: uppercase;

      @include breakpoint(desktop) {
        font-size: 22px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 20px;
      }

      @include breakpoint(ipad) {
        font-size: 18px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
      }
    }
  }

  .right_s {
    display: inline-flex;
    justify-content: center;
    text-align: center;

    figure {
      display: inline-flex;

      @include breakpoint(mobile) {
        max-width: 400px;
      }
    }
  }
}

.home_explore_sc {
  .gap_p {
    @include breakpoint(ipadLandsacpe) {
      --gap_y: 50px;
    }
  }

  .left_s {
    width: 35%;

    @include breakpoint(ipadLandsacpe) {
      width: 100%;
      text-align: center;
    }
  }

  .right_s {
    width: 65%;

    @include breakpoint(ipadLandsacpe) {
      width: 100%;
    }
  }

  .explore_swiper {
    .swiper-slide {
      height: auto;
    }
  }

  .expore_box_single {
    height: 100%;
    overflow: hidden;
    border-radius: 7px;
    background-color: $c_secondary_light;
    transition: $transition;
    padding-bottom: 25px;

    @include breakpoint(ipad) {
      border-radius: 5px;
      padding-bottom: 20px;
    }

    figure {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: inline-flex;
      transition: $transition;
      margin-bottom: 25px;
      overflow: hidden;

      @include breakpoint(ipad) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    :is(h3, p) {
      color: $c_white;
      padding-inline: 18px;

      @include breakpoint(ipad) {
        padding-inline: 15px;
      }
    }

    h3 {
      font-size: 18px;
      margin-bottom: 5px;
      @include content_limit(1);

      @include breakpoint(mobile) {
        font-size: 17px;
      }
    }

    p {
      font-size: 16px;
      @include content_limit(1);

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $c_primary;

      figure {
        img {
          transform: scale(1.05);
        }
      }
    }
  }



  .inline_arrow {
    &::before,
    &::after {
      background-color: $c_secondary;
    }
  }
}

.explore_sc {
  .gap_p {
    @include breakpoint(ipadLandsacpe) {
      --gap_y: 50px;
    }
  }

  .left_s {
    width: 35%;

    @include breakpoint(ipadLandsacpe) {
      width: 100%;
      text-align: center;
    }
  }

  .right_s {
    width: 65%;

    @include breakpoint(ipadLandsacpe) {
      width: 100%;
    }
  }

  .explore_swiper {
    .swiper-slide {
      height: auto;
    }
  }

  .expore_box_single {
    height: 100%;
    overflow: hidden;
    border-radius: 7px;
    background-color: $c_white;
    transition: $transition;
    padding-bottom: 25px;

    @include breakpoint(ipad) {
      border-radius: 5px;
      padding-bottom: 20px;
    }

    figure {
      width: 100%;
      aspect-ratio: 1 / 1;
      display: inline-flex;
      transition: $transition;
      margin-bottom: 25px;
      overflow: hidden;

      @include breakpoint(ipad) {
        margin-bottom: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    :is(h3, p) {
      color: $c_white;
      padding-inline: 18px;

      @include breakpoint(ipad) {
        padding-inline: 15px;
      }
    }

    h3 {
      font-size: 18px;
      margin-bottom: 5px;
      @include content_limit(1);

      @include breakpoint(mobile) {
        font-size: 17px;
      }
    }

    p {
      font-size: 16px;
      @include content_limit(1);

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $c_primary;

      figure {
        img {
          transform: scale(1.05);
        }
      }
    }
  }

  

  .inline_arrow {
    &::before,
    &::after {
      background-color: $c_secondary;
    }
  }
}

.home_partner_sc {
  .partner {
    &_listing {
      max-width: 980px;
      margin-inline: auto;
      margin-bottom: 70px;

      @include breakpoint(desktop) {
        margin-bottom: 50px;
      }

      @include breakpoint(ipadLandsacpe) {
        margin-bottom: 40px;
      }

      @include breakpoint(mobile) {
        margin-bottom: 30px;
      }

      .swiper-button-prev,
      .swiper-button-next {
        background-color: $c_white;

        &::after {
          color: $c_primary;
        }
      }

      &.inline_arrow {
        &::before,
        &::after {
          background-color: $c_primary;
        }
      }
    }

    &_single {
      figure {
        display: flex;
        position: relative;
        padding-bottom: 20px;
        z-index: 1;

        @include breakpoint(ipad) {
          padding-bottom: 10px;
        }

        &::after {
          content: "";
          position: absolute;
          inset: 0;
          background-color: #429655;
          border-radius: 200px;
          z-index: -1;
        }

        &::before {
          content: "";
          position: absolute;
          inset: 0 0 20px;
          border-radius: 200px;
          background-color: $c_white;
          z-index: 1;
        }

        img {
          width: 100%;
          aspect-ratio: 1 / 1.5;
          padding: 60px 30px;
          object-fit: contain;
          object-position: center;
          overflow: hidden;
          position: relative;
          z-index: 2;

          @include breakpoint(ipad) {
            padding: 60px 25px;
          }

          @include breakpoint(ipadLandsacpe) {
            padding: 45px 15px;
          }

          @include breakpoint(mobileSmall) {
            padding: 70px 20px;
          }
        }
      }
    }
  }

  .btn_flex {
    justify-content: center;
  }

  .search_bar {
    max-width: 500px;
    margin-inline: auto;
    margin-bottom: 50px;

    @include breakpoint(ipad) {
      max-width: 400px;
      margin-bottom: 30px;
    }
  }
}

.home_vendor_sc {
  .vendor {
    &_listing {
      padding-block: 50px;
      margin-block: -50px;

      .swiper-slide {
        height: auto;
      }

      &.inline_arrow {
        &::before,
        &::after {
          background-color: $c_white;
        }
      }

      .vendor_single {
        width: 100%;
        height: 100%;
      }
    }

    &_single {
      padding: 30px;
      display: flex;
      flex-direction: column;
      background-color: $c_white;
      width: calc(100% / 3 - var(--gap_y));
      box-shadow: 0px 2px 46px #0000000f;
      border: 2px solid transparent;
      transition: $transition;
      border-radius: 20px;

      @include breakpoint(desktop) {
        padding: 25px;
        border-radius: 10px;
      }

      @include breakpoint(ipad) {
        padding: 20px;
        border-radius: 5px;
        width: calc(100% / 2 - var(--gap_y));
      }

      @include breakpoint(mobileSmall) {
        padding: 20px 15px;
        width: calc(100% / 1 - var(--gap_y));
      }

      figure {
        height: 78px;
        width: 138px;
        display: inline-flex;
        align-items: center;
        margin-bottom: 20px;

        @include breakpoint(mobile) {
          margin-bottom: 15px;
        }

        img {
          max-height: 100%;
        }
      }

      h3 {
        margin-bottom: 15px;
        @include content_limit(2);

        @include breakpoint(mobile) {
          margin-bottom: 10px;
        }
      }

      p {
        margin-bottom: 5px;
        @include content_limit(3);

        @include breakpoint(mobile) {
          margin-bottom: 10px;
        }
      }

      .btn_link {
        margin-top: auto;
        margin-left: auto;
        display: inline-flex;
        cursor: pointer;
      }

      &:hover {
        border-color: $c_primary;
        transform: translateY(-5px);
      }
    }
  }

  .btn_flex {
    margin-top: 40px;
    justify-content: center;
  }

  .search_bar {
    max-width: 500px;
    margin-inline: auto;
    margin-bottom: 50px;

    @include breakpoint(ipad) {
      max-width: 400px;
      margin-bottom: 30px;
    }
  }
}

.home_testimonial_sc {
  .slick-slider {
    max-width: 900px;
    margin-inline: auto;
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(mobile) {
    margin: 0 30px;
    }
  }

  .slick-prev:before, .slick-next:before {
    color: $c_primary;
    opacity: 1;
    font-size: 25px;
}
.slick-prev {
  left: -35px;
}
.slick-next {
  right: -35px;
}

  .gallery_hr {
    margin: 35px auto;
    border: none;
    background-color: #1f242a;
    height: 1px;
    position: relative;
    overflow: visible;

    @include breakpoint(ipad) {
      margin: 25px auto;
    }

    @include breakpoint(mobile) {
      margin: 20px auto;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #1f242a;
      transform: translate(-50%, 0);

      @include breakpoint(mobile) {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #1f242a;
      }
    }
  }

  .slick-thumb {
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: initial;

    li {
      width: auto;
      height: auto;
      margin: 0;
    }
  }

  .swiper_gallery {
    max-width: 900px;
    margin-inline: auto;

    // .thumb_single {
    // @include breakpoint(ipadLarge) {
    //     display: none;
    // }
    // }

    .swiper {
      &-slide-prev:not(:only-child),
      &-slide-next:not(:only-child) {
        .thumb_single {
          figure {
            width: 95px;
            height: 95px;
            min-width: 95px;
            border-color: transparent;
            pointer-events: none;
            position: absolute;
            top: 21.5px;
            z-index: 1;

            @include breakpoint(mobile) {
              position: initial;
            }

            img {
              filter: grayscale(1);
            }
          }
        }
      }

      &-slide-prev:not(:only-child) {
        .thumb_single {
          figure {
            left: 122%;
          }
        }
      }

      &-slide-next:not(:only-child) {
        .thumb_single {
          figure {
            right: 122%;
          }
        }
      }

      &-button-prev,
      &-button-next {
        top: 28.5px;
        position: absolute;
        transform: none;
        height: 81px;
        margin: 0;
        width: calc(50% - (150px / 2));
        border-radius: 0;
        opacity: 0;
      }

      &-button-prev {
        left: 0;
      }

      &-button-next {
        right: 0;
      }
    }
  }

  .thumb_single {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 138px;
    cursor: pointer;

    @include breakpoint(ipad) {
      min-height: 120px;
    }

    figure {
      width: 138px;
      min-width: 138px;
      aspect-ratio: 1/1;
      display: inline-flex;
      border: 2px solid transparent;
      border-radius: 50%;
      padding: 5px;
      margin: auto;

      @include breakpoint(ipad) {
        width: 120px;
        min-width: 120px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        filter: grayscale(1);
        background-color: #f9f9f9;
      }
    }
  }

  .slick-current {
    .thumb_single {
      figure {
        border: 2px solid $c_primary;

        img {
          filter: none;
        }
      }
    }
  }

  .slick-slide:not(.slick-current) {
    .thumb_single {
      figure {
        transform: scale(0.9);
      }
    }
  }

  .swiper_thumb {
    display: none;

    // @include breakpoint(mobile) {
    //     display: none;
    // }

    .swiper-slide {
      &:not(.swiper-slide-active) {
        .thumb_single {
          figure {
            border-color: transparent;

            img {
              filter: grayscale(1);
            }
          }
        }
      }

      &.swiper-slide-active {
        .thumb_single {
          figure {
            width: 75px;
            height: 75px;
            min-width: 75px;
            margin-right: 0px;
            border-color: transparent;

            img {
              filter: grayscale(1);
            }
          }
        }

        + .swiper-slide + .swiper-slide {
          .thumb_single {
            figure {
              border-color: $c_primary;

              img {
                filter: none;
              }
            }
          }
        }

        + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
          .thumb_single {
            figure {
              width: 75px;
              height: 75px;
              min-width: 75px;
            }
          }
        }

        + .swiper-slide,
        + .swiper-slide + .swiper-slide + .swiper-slide {
          .thumb_single {
            figure {
              width: 95px;
              height: 95px;
              min-width: 95px;
            }
          }
        }

        + .swiper-slide {
          .thumb_single {
            figure {
              margin-right: 50px;

              @include breakpoint(ipad) {
                margin: auto;
              }
            }
          }
        }

        + .swiper-slide + .swiper-slide + .swiper-slide {
          .thumb_single {
            figure {
              margin-left: 50px;

              @include breakpoint(ipad) {
                margin: auto;
              }
            }
          }
        }

        + .swiper-slide + .swiper-slide + .swiper-slide + .swiper-slide {
          .thumb_single {
            figure {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }

  .gallery_single {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    gap: 10px;

    p {
      font-weight: 500;
      margin: 0;
    }

    img {
      min-width: 85px;
      max-width: 85px;
      object-fit: scale-down;

      &:first-child {
        object-position: top left;
      }

      &:last-child {
        object-position: top right;
      }

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  .gallery_head {
    text-align: center;
    padding-top: 15px;

    h3 {
      font-weight: 700;
      margin-bottom: 3px;
    }

    p {
      opacity: 0.6;
      font-weight: 500;
    }
  }
}

.home_blogs_sc {
  .blog {
    &_single {
      padding: 15px;
      display: flex;
      flex-direction: column;
      width: calc(100% / 3 - var(--gap_y));
      box-shadow: 0px 2px 46px #0000000f;
      border: 2px solid transparent;
      background-color: $c_white;
      transition: $transition;
      border-radius: 20px;
      cursor: pointer;

      @include breakpoint(desktop) {
        padding: 10px;
        border-radius: 10px;
      }

      @include breakpoint(ipad) {
        width: calc(100% / 2 - var(--gap_y));
      }

      @include breakpoint(mobileSmall) {
        width: calc(100% / 1 - var(--gap_y));
      }

      figure {
        width: 100%;
        aspect-ratio: 1/0.5;
        border-radius: 20px;
        margin-bottom: 15px;
        overflow: hidden;

        @include breakpoint(desktop) {
          border-radius: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: $transition;
        }
      }

      .tag {
        display: flex;
        font-size: 17px;
        font-weight: 700;
        color: $c_primary;
        line-height: 1.2;
        margin-bottom: 5px;

        @include breakpoint(ipad) {
          font-size: 16px;
        }
      }

      h3 {
        margin-bottom: 10px;
        @include content_limit(2);
      }

      p {
        font-size: 15px;
        font-weight: 600;
        margin-top: auto;

        @include breakpoint(desktop) {
          font-size: 14px;
        }
      }

      &:hover {
        border-color: $c_primary;
        transform: translateY(-5px);

        figure {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .btn_flex {
    margin-top: 40px;
    justify-content: center;
  }
}

.joinMovement_sc {
  .s_head {
    p {
      font-size: 30px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      margin: -40px 0 0;
      gap: 20px;

      @include breakpoint(desktop) {
        font-size: 25px;
      }

      @include breakpoint(ipad) {
        font-size: 20px;
        margin: -30px 0 0;
        gap: 10px;
      }

      @include breakpoint(mobile) {
        font-size: 18px;
        margin: -10px 0 20px;
        flex-direction: column-reverse;
        gap: 0px;
      }

      img {
        @include breakpoint(desktop) {
          height: 90px;
        }

        @include breakpoint(ipad) {
          height: 70px;
        }
      }
    }
  }

  .btn_flex {
    justify-content: center;

    @include breakpoint(mobile) {
      flex-direction: column;
    }
  }
}

.home_news_sc {
  .btn_flex {
    margin-top: 40px;
    justify-content: center;
  }
}

.partner_single {
  cursor: pointer;
}
