.site_header {
  transition: $transition;

  &.scrolled {
    +.main_header {
      box-shadow: $shadow;
    }
  }
}

.site_logo {
  display: inline-flex;
  cursor: pointer;

  figure {
    display: inline-flex;

    img {
      height: 30px;

      @include breakpoint(mobile) {
        height: 24px;
      }

      @include breakpoint(mobileSmall) {
        height: 20px;
      }
    }
  }
}

.social_icons {
  display: inline-flex;
  align-items: center;

  li {
    margin: 0;
    display: inline-flex;

    a {
      display: inline-flex;

      img {
        transition: $transition;

        @include breakpoint(ipadLandsacpe) {
          max-width: 17px;
          max-height: 17px;
        }
      }

      &:hover {
        img {
          filter: brightness(0) invert(66%) sepia(32%) saturate(651%) hue-rotate(81deg) brightness(90%) contrast(90%);
        }
      }
    }
  }
}

.top_header {
  padding: 10px 0;
  background-color: $c_white;

  .d_flex {
    display: flex;
    align-items: center;
    gap: 30px;

    @include breakpoint(ipadLandsacpe) {
      gap: 25px;
    }

    @include breakpoint(ipad) {
      gap: 20px;
    }

    @include breakpoint(mobile) {
      justify-content: space-between;
    }
  }

  p {
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;

    @include breakpoint(ipadLandsacpe) {
      font-size: 18px;
    }

    @include breakpoint(ipad) {
      font-size: 16px;
      font-weight: 700;
    }

    @include breakpoint(mobile) {
      display: none;
    }
  }

  .social_icons {
    gap: 20px;
    margin: 0 0 0 auto;

    @include breakpoint(ipadLandsacpe) {
      gap: 15px;
    }

    @include breakpoint(ipad) {
      gap: 12px;
    }

    @include breakpoint(mobile) {
      margin-left: initial;
    }
  }

  .btn {
    font-size: 16px;
    min-height: 40px;
    padding: 2px 10px;
    min-width: 1px;
    text-transform: none;

    @include breakpoint(ipadLandsacpe) {
      font-size: 14px;
      padding: 2px 15px;
    }

    @include breakpoint(ipad) {
      padding: 2px 10px;
    }

    @include breakpoint(mobile) {
      min-height: 30px;
      font-size: 14px;
      border-radius: 2px;
    }
  }
}

.main_header {
  padding: 10px 0;
  background-color: $c_secondary;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  @include breakpoint(desktop) {
    padding: 15px 0;
  }

  @include breakpoint(mobile) {
    padding: 12px 0;
  }

  &.open {
    @include breakpoint(ipadLandsacpe) {
      background: linear-gradient(0deg, $c_black 54px, $c_secondary 54px);
    }

    @include breakpoint(mobile) {
      background: linear-gradient(0deg, $c_black 48px, $c_secondary 48px);
    }

    @include breakpoint(mobileSmall) {
      background: linear-gradient(180deg, $c_secondary 55px, $c_black 55px);
    }
  }

  .d_flex {
    display: flex;
    align-items: center;

    @include breakpoint(ipadLandsacpe) {
      flex-wrap: wrap;
    }
  }

  .site_hamburgur {
    display: none;
    color: $c_white;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    margin-right: 25px;

    @include breakpoint(ipadLandsacpe) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    @include breakpoint(mobile) {
      height: 30px;
      width: 30px;
      border-radius: 2px;
      margin-right: 15px;
    }

    svg {
      font-size: 24px;

      @include breakpoint(mobile) {
        font-size: 20px;
      }
    }

    &.active {
      color: #ff0000;
      background-color: $c_white;
    }
  }

  .site_menu {
    display: inline-flex;
    align-items: center;
    margin: 0 55px 0 auto;

    @include breakpoint(desktop) {
      margin-right: 40px;
    }

    @include breakpoint(ipadLandsacpe) {
      display: none;
    }

    @include breakpoint(mobileSmall) {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      margin: 0;

      @include breakpoint(mobileSmall) {
        width: 100%;
      }

      &:not(:first-child) {
        margin-left: 30px;

        @include breakpoint(desktop) {
          margin-left: 25px;
        }

        @include breakpoint(mobileSmall) {
          margin-left: 0;
          border-top: 1px solid rgba(255, 255, 255, 0.1)
        }
      }

      a,button {
        color: $c_white;
        font-size: 15px;
        font-weight: 500;
        font-family: $f_body;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: capitalize;
        letter-spacing: 0;
        line-height: 1.4;
        padding: 0;

        @include breakpoint(desktop) {
          font-size: 14px;
        }

        @include breakpoint(mobileSmall) {
          width: 100%;
          padding: 10px 0;
        }

        svg {
          font-size: 20px;

          @include breakpoint(desktop) {
            font-size: 18px;
          }
        }

        &:hover {
          color: $c_primary;
        }
      }
    }

    &.open {
      @include breakpoint(ipadLandsacpe) {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 30px;
        margin-right: 0;
        order: 9;
      }

      @include breakpoint(mobile) {
        padding-top: 24px;
      }
    }
  }

  .site_action {
    display: inline-flex;
    align-items: center;
    gap: 20px;

    @include breakpoint(desktop) {
      gap: 15px;
    }

    @include breakpoint(ipadLandsacpe) {
      margin-left: auto;
    }

    @include breakpoint(mobileSmall) {
      gap: 10px;
    }

    .btn {
      min-height: 50px;
      min-width: 146px;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;

      @include breakpoint(desktop) {
        min-height: 40px;
        min-width: 110px;
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        min-height: 30px;
        min-width: 80px;
        font-size: 14px;
        padding: 3px 10px;
        border-radius: 2px;
      }
    }

    .link_box {
      display: inline-flex;
      align-items: center;
      position: relative;

      @include breakpoint(mobile) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #f2f2f2;
        font-size: 14px;
        font-weight: 800;
        min-height: 40px;
        padding: 5px 12px;
        border-radius: 5px;
        cursor: pointer;
        margin: 0;
        gap: 10px;

        @include breakpoint(ipadLandsacpe) {
          min-height: 35px;
          padding: 5px 10px;
        }

        @include breakpoint(mobile) {
          border-radius: 0;
          justify-content: center;
          min-height: 40px;
          padding: 5px 15px;
          width: 100%;
          gap: 15px;
        }

        span {
          width: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }

        figure {
          display: inline-flex;
          cursor: pointer;

          img {
            height: 26px;

            @include breakpoint(ipadLandsacpe) {
              height: 22px;
            }

            @include breakpoint(mobile) {
              height: 20px;
            }
          }
        }
      }

      >span {
        position: absolute;
        left: 50%;
        top: calc(100% + 15px);
        font-size: 13px;
        font-weight: 400;
        background-color: $c_white;
        white-space: nowrap;
        padding: 6px 10px;
        border-radius: 3px;
        transform: translateX(-50%);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        &::before {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 100%;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid #fff;
        }
      }
    }
  }

  .profile_btn {
    a {
      color: $c_white;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      font-family: $f_body;
      text-transform: capitalize;
      transition: $transition;
      letter-spacing: 0;
      line-height: 1.4;
      min-width: 1px;
      padding: 0;
      gap: 10px;

      @include breakpoint(mobile) {
        gap: 5px;
      }

      span {
        display: inline-block;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include breakpoint(mobileSmall) {
          display: none;
        }
      }

      figure {
        display: inline-flex;

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;

          @include breakpoint(desktop) {
            width: 40px;
            height: 40px;
          }

          @include breakpoint(mobile) {
            width: 30px;
            height: 30px;
          }
        }
      }

      svg {
        margin-left: -10px;
        font-size: 20px;

        @include breakpoint(desktop) {
          font-size: 18px;
        }

        @include breakpoint(mobile) {
          margin-left: -5px;
        }

        @include breakpoint(mobileSmall) {
          display: none;
        }
      }

      &:hover {
        color: $c_primary;
      }
    }
  }

  .btn_icon {
    padding: 0;
    min-width: 1px;
    letter-spacing: 0;
    border: none;
    box-shadow: none;

    img {
      height: 38px;

      @include breakpoint(desktop) {
        height: 30px;
      }

      @include breakpoint(mobile) {
        height: 25px;
      }
    }
  }
}

.menu_dropdown {
  .MuiPaper-root {
    padding-top: 23px;
    box-shadow: 0px 2px 16px #00000029;
    background-color: transparent;

    @include breakpoint(desktop) {
      padding-top: 24px;
    }

    @include breakpoint(ipadLandsacpe) {
      padding-top: 16px;
    }

    @include breakpoint(mobile) {
      padding-top: 13px;
    }

    @include breakpoint(mobileSmall) {
      padding-top: 1px;
      margin-left: -1px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 12px;
      top: 17px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid $c_white;

      @include breakpoint(desktop) {
        top: 18px;
      }

      @include breakpoint(ipadLandsacpe) {
        top: 10px;
      }

      @include breakpoint(mobile) {
        display: none;
      }
    }
  }

  ul {
    padding: 10px 0;
    min-width: 150px;
    background-color: $c_white;

    li {
      display: flex;
      margin: 0;

      a {
        width: 100%;
        display: flex;
        color: $c_text;
        font-size: 14px;
        font-weight: 600;
        padding: 4px 12px;
        transition: $transition;

        &:hover {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }
  }

  &User {
    .MuiPaper-root {
      padding-top: 10px;

      @include breakpoint(desktop) {
        padding-top: 15px;
      }

      @include breakpoint(mobile) {
        padding-top: 12px;
      }

      @include breakpoint(mobileSmall) {
        margin-left: 0;
      }

      &::before {
        top: 4px;

        @include breakpoint(desktop) {
          top: 9px;
        }

        @include breakpoint(mobile) {
          display: none;
        }
      }
    }
  }
}

.site_footer {
  background-color: $c_secondary;
}

.main_footer {
  padding: 75px 0 40px;
  display: flex;
  justify-content: space-between;
  gap: 30px;

  @include breakpoint(ipadLandsacpe) {
    padding: 60px 0 40px;
    gap: 20px;
  }

  @include breakpoint(ipad) {
    flex-wrap: wrap;
    padding: 50px 0 40px;
    gap: 45px 20px;
  }

  @include breakpoint(mobile) {
    padding: 40px 0;
    gap: 35px 10px;
  }

  .single {
    max-width: 290px;

    @include breakpoint(ipadLandsacpe) {
      max-width: 240px;
    }

    @include breakpoint(mobile) {
      width: 100%;
      max-width: 100%;
    }

    &:first-child {
      max-width: 250px;

      @include breakpoint(ipadLandsacpe) {
        max-width: 220px;
      }

      @include breakpoint(ipad) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:last-child {
      width: 100%;
    }

    .site_logo {
      margin-bottom: 15px;
cursor: pointer;
      +p {
        @include breakpoint(ipadLandsacpe) {
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }

    :where(h2, p, li, a) {
      color: $c_white;
    }

    h2 {
      margin-bottom: 30px;

      @include breakpoint(ipadLandsacpe) {
        font-size: 18px;
        padding-top: 5px;
        margin-bottom: 24px;
      }

      @include breakpoint(mobile) {
        padding-top: 0;
        margin-bottom: 18px;
      }
    }

    ul:not(.social_icons) {
      li {
        display: flex;

        @include breakpoint(mobile) {
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        a {
          font-size: 16px;
          font-weight: 400;
          display: inline-flex;
          line-height: 1.2;

          @include breakpoint(ipadLandsacpe) {
            font-size: 14px;
          }

          &:hover {
            color: $c_primary;
          }
        }
      }
    }

    .social_icons {
      display: flex;
      margin-top: 20px;

      @include breakpoint(mobile) {
        margin-top: 15px;
      }

      li {
        margin: 0 10px 0 0;

        a {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: $c_white;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          @include breakpoint(ipad) {
            width: 35px;
            height: 35px;
          }

          img {
            max-width: 17px;
            max-height: 17px;
          }
        }
      }
    }
  }

  .form {
    &_control {
      margin-bottom: 15px;

      @include breakpoint(ipadLandsacpe) {
        margin-bottom: 10px;
      }

      input {
        height: 40px;
        border-radius: 10px;

        @include breakpoint(ipadLandsacpe) {
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 14px;
        }
      }

      p {
        color: #909090;
        font-size: 14px;
        font-weight: 400;

        @include breakpoint(ipadLandsacpe) {
          font-size: 12px;
        }

        a {
          color: $c_primary;
          text-decoration: underline;
        }
      }
    }

    &_btn {
      margin-top: 20px;
      text-align: right;

      .btn {
        min-height: 40px;
        min-width: 130px;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        width: auto;
      }
    }
  }
}

.copywrite_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #5C5C5C;
  padding: 20px 0;
  gap: 30px;

  @include breakpoint(mobile) {
    text-align: center;
    flex-direction: column;
    gap: 15px 0;
  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: $c_white;

    @include breakpoint(ipadLandsacpe) {
      font-size: 14px;
    }

    &:first-child {
      @include breakpoint(mobile) {
        max-width: 240px;
      }
    }
  }
}

.chatBot_icon {
  position: fixed;
  display: inline-flex;
  right: 20px;
  bottom: 20px;
  z-index: 99;

  @include breakpoint(mobile) {
    right: 15px;
    bottom: 15px;
  }

  figure {
    display: inline-flex;
    cursor: pointer;
    filter: drop-shadow(0px 2px 50px rgba(0, 0, 0, 0.5));

    @include breakpoint(ipad) {
      height: 50px;
    }

    @include breakpoint(mobile) {
      height: 40px;
    }
  }
}

.site_noti {
  display: flex;
  align-items: center;
  gap: 8px;

  .btn_icon {
    color: $c_white;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    @include breakpoint(mobile) {
      height: 30px;
      width: 30px;
      border-radius: 2px;
    }

    svg {
      font-size: 20px;

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }

    &:hover {
      color: $c_primary;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.site_cart {
  display: inline-flex;
  cursor: pointer;
  svg {
    font-size: 20px;
  }
}

@include breakpoint(mobile) {
  body:has(.main_header .site_action .link_box) {
    padding-top: 40px;

    .main_header {
      top: 40px;
    }
  }
}

.link-style {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
}

.wallet-points-dropdown {
  position: relative;
  height: 40px;

  .points-dropdown-button {
    padding: 0;
    min-width: auto;
    background: transparent;
    
    &:hover {
      background: transparent;
    }
  }

  .wallet-points {
    height: 40px;
    background: rgba(255, 255, 255, 0.15);
    padding: 8px 12px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    transition: all 0.2s ease;

    .points-value {
      color: #fff;
      font-size: 16px;
      font-weight: 500;
    }

    .points-label {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      font-weight: 400;
    }

    .dropdown-arrow {
      width: 12px;
      height: 12px;
      margin-left: 4px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}

.points-dropdown-menu {
  cursor: pointer;

  .MuiPaper-root {
    background: #1a1f2e;
    border-radius: 12px;
    margin-top: 8px;
    min-width: 220px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }

  .points-menu-content {
    padding: 12px;
  }

  .points-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    color: #fff;
    font-size: 15px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    span:first-child {
      color: rgba(255, 255, 255, 0.7);
    }

    .points-value {
      font-weight: 500;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
    }
  }
}