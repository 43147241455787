@import '../../variable.scss';

.page_corporateSites {
    .iconBoxes {
        padding-top: 65px;

        @include breakpoint(ipadLandsacpe) {
            padding-top: 50px;
        }

        @include breakpoint(mobile) {
            padding-top: 40px;
        }

        .gap_m {
            --gap_y: 95px;

            @include breakpoint(ipadLandsacpe) {
                --gap_y: 80px;
            }

            @include breakpoint(mobile) {
                --gap_y: 60px;
            }
        }

        &.v2 {
            padding-top: 46px;

            .gap_m {
                --gap_y: 76px;
                --gap_x: 15px;
            }
        }

        &_single {
            padding: 100px 30px 30px;
            width: calc(100% / 3 - var(--gap_x));
            box-shadow: 0px 2px 56px #0000001A;
            background-color: $c_white;
            border-radius: 20px;
            position: relative;
            text-align: center;

            @include breakpoint(ipadLandsacpe) {
                width: calc(100% / 2 - var(--gap_x));
                padding: 75px 15px 25px;
                border-radius: 15px;
            }

            @include breakpoint(ipad) {
                border-radius: 10px;
            }

            @include breakpoint(mobile) {
                padding: 60px 15px 20px;
                border-radius: 5px;
            }

            @include breakpoint(mobileSmall) {
                width: calc(100% / 1 - var(--gap_x));
            }

            figure {
                position: absolute;
                left: 50%;
                top: 0;
                width: 128px;
                height: 128px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                transform: translate(-50%, -50%);
                background: transparent linear-gradient(180deg, #61C077 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
                border-radius: 50%;

                @include breakpoint(ipadLandsacpe) {
                    width: 100px;
                    height: 100px;
                }

                @include breakpoint(mobile) {
                    width: 80px;
                    height: 80px;
                }

                img {
                    max-width: 57px;
                    max-height: 57px;

                    @include breakpoint(ipadLandsacpe) {
                        max-width: 40px;
                        max-height: 40px;
                    }

                    @include breakpoint(mobile) {
                        max-width: 30px;
                        max-height: 30px;
                    }
                }
            }

            h3 {
                font-weight: 700;
                margin-bottom: 15px;
            }

            p {
                font-weight: 500;
            }

            &.v2 {
                padding-top: 80px;
                width: calc(100% / 4 - var(--gap_x));

                @include breakpoint(ipadLandsacpe) {
                    width: calc(100% / 2 - var(--gap_x));
                    padding-top: 75px;
                }

                @include breakpoint(mobile) {
                    padding-top: 60px;
                }

                @include breakpoint(mobileSmall) {
                    width: calc(100% / 1 - var(--gap_x));
                }

                figure {
                    width: 92px;
                    height: 92px;

                    @include breakpoint(ipadLandsacpe) {
                        width: 100px;
                        height: 100px;
                    }

                    @include breakpoint(mobile) {
                        width: 80px;
                        height: 80px;
                    }

                    img {
                        max-width: 41px;
                        max-height: 41px;

                        @include breakpoint(ipadLandsacpe) {
                            max-width: 40px;
                            max-height: 40px;
                        }

                        @include breakpoint(mobile) {
                            max-width: 30px;
                            max-height: 30px;
                        }
                    }
                }
            }
        }
    }
}

.Corp_hero,
.Corp_sc5,
.Corp_trusted_sc {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        inset: 0;
        position: absolute;
        z-index: -1;
    }
}

.Corp_hero {
    &::before {
        background: transparent linear-gradient(114deg, #000000 0%, #122A46 57%, #56BB6D 100%) 0% 0% no-repeat padding-box;
        opacity: 0.8;
    }

    .s_hd {
        max-width: 570px;

        h1 {
            font-size: 40px;
            font-weight: 700;
            margin-bottom: 20px;

            @include breakpoint(ipadLandsacpe) {
                font-size: 36px;
                margin-bottom: 15px;
            }

            @include breakpoint(ipad) {
                font-size: 32px;
            }
        }

        p {
            font-size: 22px;

            @include breakpoint(ipadLandsacpe) {
                font-size: 20px;
            }

            @include breakpoint(ipad) {
                font-size: 18px;
            }

            @include breakpoint(mobile) {
                font-size: 16px;
            }
        }
    }
}

.Corp_sc2 {
    .inner {
        .gap_p {
            align-items: center;
        }

        :is(.left_s, .right_s) {
            width: 50%;

            @include breakpoint(mobile) {
                width: 100%;
                text-align: center;
            }
        }

        .left_s {
            figure {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;

                @include breakpoint(ipadLandsacpe) {
                    max-width: 330px;
                }

                @include breakpoint(mobile) {
                    max-width: 200px;
                }
            }
        }

        .s_hd {
            max-width: 575px;

            h2 {
                font-size: 24px;
                font-weight: 500;
                line-height: 1.5;

                @include breakpoint(ipadLandsacpe) {
                    font-size: 20px;
                    font-weight: 600;
                }

                @include breakpoint(ipad) {
                    font-size: 18px;
                    line-height: 1.4;
                }

                @include breakpoint(mobile) {
                    font-size: 16px;
                }
            }

            p {
                font-weight: 500;
            }
        }
    }
}

.Corp_sc3 {
    .gap_p {
        align-items: center;

        @include breakpoint(ipad) {
            flex-direction: column-reverse;
        }
    }

    :is(.left_s, .right_s) {
        width: 50%;

        @include breakpoint(ipad) {
            width: 100%;
            text-align: center;
        }
    }

    .right_s {
        figure {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            @include breakpoint(ipadLandsacpe) {
                max-width: 330px;
            }

            @include breakpoint(ipad) {
                max-width: 200px;
            }

            @include breakpoint(mobile) {
                max-width: 180px;
            }
        }
    }
}

.Corp_sc5 {
    &::before {
        background: transparent linear-gradient(132deg, #000000 0%, #122A46 100%, ) 0% 0% no-repeat padding-box;
        opacity: 0.9;
    }

    .s_hd {
        max-width: 630px;

        h3 {
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1.3;

            @include breakpoint(ipadLandsacpe) {
                margin-bottom: 20px;
            }
        }
    }
}

.Corp_trusted_sc {
    &::before {
        background: transparent linear-gradient(132deg, #000000 0%, #122A46 100%, ) 0% 0% no-repeat padding-box;
        opacity: 0.9;
    }

    figure {
        display: flex;
        text-align: center;
        justify-content: center;
        margin: 0 auto;

        @include breakpoint(ipadLandsacpe) {
            max-width: 600px;
        }

        @include breakpoint(ipad) {
            max-width: 500px;
        }

        @include breakpoint(mobile) {
            max-width: 100%;
        }
    }
}