@import "../../variable.scss";

.blogDetail_sc {
    .inner {
        max-width: 750px;
        width: 100%;
        margin: 0 auto;
    }

    .blog_figure {
        width: 100%;
        border-radius: 0;
        margin-bottom: 20px;
        display: flex;

        img {
            width: 100%;
        }
    }

    .tag {
        display: flex;
        font-size: 17px;
        font-weight: 700;
        color: #56BB6D;
        line-height: 1.2;
        margin-bottom: 5px;

        @include breakpoint(ipad) {
            font-size: 16px;
        }
    }

    .hd_3 {
        margin: 10px 0 15px;
    }

    h1 {
        +p {
            font-size: 15px;
            font-weight: 600;
        }
    }

    .blog_desc {

        :is(h1, h2, h3, h4, h5, h6) {
            font-weight: 700;
            margin-bottom: 15px;
            margin-top: 35px;

            @include breakpoint(mobile) {
                margin-block: 30px 12px;
            }
        }

        p {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}