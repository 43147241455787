@import "./variable.scss";
@import './style.scss';
@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $c_white;
}

::-webkit-scrollbar-thumb {
  background-color: $c_primary;
}

.font {
  &_Bw {
    font-family: $f_body;

    :where(*) {
      font-family: $f_body;
    }
  }
}

* {
  outline: none !important;
  margin: 0;
  box-sizing: border-box;
  font-family: $f_body !important;
}

html {
  scroll-behavior: auto;

  touch-action: manipulation;
  /* Prevent double-tap zooming */
  -ms-touch-action: manipulation;
  /* IE 10+ */

  @include breakpoint(mobile) {
    user-scalable: none;
    /* Prevent zooming */
    -ms-content-zooming: none;
    /* IE 10 */
    -ms-touch-action: none;
    /* IE 10 */
    zoom: 1;
    /* Set initial zoom level */
    max-width: 100%;
    /* Prevent horizontal scrolling */
  }
}

body {
  background-color: $c_white;
}

a,
a:hover {
  transition: $transition;
}

b,
strong {
  font-weight: 600;
}

a,
a:not(.btn):hover {
  text-decoration: none;
}

:is(h1, h2, h3, h4, h5, h6) {
  color: $c_heading;
  margin-bottom: 0;
  font-family: $f_body;
}

h1,
.hd_1 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 56px;
  font-weight: 900;
  line-height: 1.2;

  @include breakpoint(desktop) {
    font-size: 50px;
  }

  @include breakpoint(ipadLandsacpe) {
    font-size: 44px;
  }

  @include breakpoint(ipad) {
    font-size: 40px;
  }

  @include breakpoint(mobile) {
    font-size: 32px;
  }
}

h2,
.hd_2 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 50px;
  font-weight: 800;
  line-height: 1.2;

  @include breakpoint(desktop) {
    font-size: 45px;
  }

  @include breakpoint(ipadLandsacpe) {
    font-size: 40px;
  }

  @include breakpoint(ipad) {
    font-size: 35px;
  }

  @include breakpoint(mobile) {
    font-size: 28px;
  }
}

h3,
.hd_3 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;

  @include breakpoint(desktop) {
    font-size: 34px;
  }

  @include breakpoint(ipadLandsacpe) {
    font-size: 30px;
  }

  @include breakpoint(mobile) {
    font-size: 25px;
  }
}

h4,
.hd_4 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;

  @include breakpoint(desktop) {
    font-size: 28px;
  }

  @include breakpoint(ipadLandsacpe) {
    font-size: 26px;
  }

  @include breakpoint(ipad) {
    font-size: 25px;
  }

  @include breakpoint(mobile) {
    font-size: 22px;
  }
}

h5,
.hd_5 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 26px;
  font-weight: 900;
  line-height: 1.2;

  @include breakpoint(desktop) {
    font-size: 24px;
  }

  @include breakpoint(ipad) {
    font-size: 22px;
  }

  @include breakpoint(mobile) {
    font-size: 20px;
  }
}

h6,
.hd_6 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;

  @include breakpoint(ipad) {
    font-size: 18px;
  }
}

body,
p,
li {
  color: $c_text;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;

  @include breakpoint(ipadLandsacpe) {
    font-size: 16px;
  }

  @include breakpoint(mobile) {
    font-size: 14px;
  }
}

p,
li {
  margin: 0;

  &.f_med {
    font-weight: 500;
  }
}

:is(p, ul, ol, li):not(:last-child) {
  margin-bottom: 16px;
}

:is(li.MuiMenuItem-root) {
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 500;
  min-height: 1px;

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.s_hd {
  h2 {
    margin-bottom: 20px;

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 16px;
    }
  }

  :is(h2, p) {
    &:has(+ .btn) {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 35px;

    @include breakpoint(ipadLandsacpe) {
      margin-top: 25px;
    }
  }

  &.text_white {
    :is(h1, h2, h3, h4, h5, h6, p) {
      color: $c_white;
    }
  }
}

.m_hd {
  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 26px;
    line-height: 1.2;

    @include breakpoint(desktop) {
      font-size: 24px;
    }

    @include breakpoint(ipadLandsacpe) {
      font-size: 22px;
    }

    @include breakpoint(ipad) {
      font-size: 20px;
    }

    @include breakpoint(mobile) {
      font-size: 18px;
    }

    @include breakpoint(mobileSmall) {
      font-size: 16px;
    }
  }

  :is(h1, p) {
    &:has(+ .btn) {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-top: 30px;
  }

  &.text_white {
    :is(h1, p) {
      color: $c_white;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

figure {
  margin: 0;
}

.btn,
.btn.MuiButton-root {
  height: auto;
  min-height: 57px;
  min-width: 200px;
  font-size: 22px;
  font-weight: 700;
  padding: 5px 25px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  text-indent: 0px;
  position: relative;
  letter-spacing: 0px;
  text-transform: capitalize;
  box-shadow: none !important;
  transition: $transition;
  border: 2px solid transparent;
  font-family: $f_body;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @include breakpoint(desktop) {
    min-height: 50px;
    min-width: 180px;
    font-size: 20px;
    padding: 5px 20px;
  }

  @include breakpoint(ipadLandsacpe) {
    font-size: 18px;
  }

  @include breakpoint(mobile) {
    font-size: 16px;
    min-width: 150px;
  }

  &.btn_lg {
    min-height: 70px;
    min-width: 225px;
    font-size: 26px;
    border-radius: 10px;
    padding: 5px 45px;

    @include breakpoint(desktop) {
      min-height: 60px;
      min-width: 200px;
      font-size: 22px;
      border-radius: 5px;
      padding: 5px 20px;
    }

    @include breakpoint(ipadLandsacpe) {
      min-height: 50px;
      min-width: 180px;
      font-size: 18px;
    }

    @include breakpoint(mobile) {
      font-size: 16px;
      min-width: 150px;
    }
  }

  // &::before {
  //   content: "";
  //   top: 0;
  //   width: 100%;
  //   position: absolute;
  //   bottom: 0;
  //   z-index: -1;
  //   animation: slide 2s infinite;
  //   background: linear-gradient(to right,
  //       rgba(64, 235, 241, 0) 0%,
  //       rgba(255, 254, 253, 0.56) 50%,
  //       rgba(255, 254, 253, 0.28) 69%,
  //       rgba(64, 235, 241, 0) 100%);
  // }

  &.btn_primary {
    color: $c_white;
    background-color: $c_primary;
    border-color: $c_primary;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: transparent;
      border-color: $c_primary;
    }

    &.br {
      color: $c_white;
      background-color: $c_primary;
      border-color: #9d9d9d;

      &:is(:hover, :focus) {
        color: $c_secondary;
        background-color: $c_primary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_primary_WA {
    color: $c_white;
    background-color: $c_primary;
    border-color: $c_primary;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: transparent;
      border-color: $c_primary;
    }

    &.br {
      color: $c_white;
      background-color: $c_primary;
      border-color: #9d9d9d;

      &:is(:hover, :focus) {
        color: $c_secondary;
        background-color: $c_primary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_primary_TG {
    color: $c_white;
    background-color: $c_primary;
    border-color: $c_primary;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: transparent;
      border-color: $c_primary;
    }

    &.br {
      color: $c_white;
      background-color: $c_primary;
      border-color: #9d9d9d;

      &:is(:hover, :focus) {
        color: $c_secondary;
        background-color: $c_primary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_secondary {
    color: $c_primary;
    background-color: $c_secondary;
    border-color: $c_secondary;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: $c_white;
      border-color: $c_secondary;
    }
  }

  &.btn_danger {
    color: $c_white;
    background-color: $c_danger;
    border-color: $c_danger;

    &:is(:hover, :focus) {
      color: $c_danger;
      background-color: $c_white;
      border-color: $c_danger;
    }
  }

  &.btn_white {
    color: $c_text;
    background-color: $c_white;
    border-color: $c_white;

    &:is(:hover, :focus) {
      color: $c_white;
      background-color: $c_primary;
      border-color: $c_primary;
    }

    &.br {
      color: $c_white;
      background-color: transparent;
      border-color: $c_white;

      &:is(:hover, :focus) {
        color: $c_white;
        background-color: $c_secondary;
        border-color: $c_secondary;
      }
    }
  }

  &.wallet_btn {
    border-color: $c_primary;
  }
}

.btn {
  &_link {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    color: $c_primary;
    display: inline-flex;
    align-items: center;
    transition: $transition;
    background: transparent;
    cursor: pointer;
    border: none;
    padding: 0;
    gap: 8px;

    @include breakpoint(mobile) {
      font-size: 14px;
    }

    &.sm {
      font-size: 12px;
      font-weight: 600;
    }

    svg {
      font-size: 13px;
    }

    &:hover {
      text-decoration: underline !important;
      text-underline-offset: 3px;
    }
  }

  &_rounded {
    width: 60px;
    height: 60px;
    color: $c_white;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $c_primary;
    border: none;
    outline: none;
    transition: $transition;
    cursor: pointer;

    @include breakpoint(desktop) {
      width: 50px;
      height: 50px;
    }

    svg {
      font-size: 30px;

      @include breakpoint(desktop) {
        font-size: 25px;
      }
    }

    &:hover {
      background-color: $c_secondary;
    }
  }
}

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.form {
  &_control {
    margin-bottom: 20px;

    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }

    .gap_p {
      --gap_x: 24px;
      --gap_y: 24px;

      @include breakpoint(mobile) {
        --gap_x: 15px;
        --gap_y: 15px;
      }
    }

    h5 {
      color: $c_white;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 30px;
    }

    label {
      color: #393939;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      display: block;

      @include breakpoint(ipadLandsacpe) {
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;

        @include breakpoint(ipadLandsacpe) {
          margin-bottom: 5px;
        }
      }

      h6 {
        color: $c_white;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
      }
    }

    :is(input, select, .MuiSelect-select, textarea) {
      width: 100%;
      color: $c_text;
      font-size: 16px;
      font-weight: 400;
      border-radius: 5px;
      border: 1px solid $c_white;
      background: $c_white;
      outline: none;
      box-shadow: none;
      box-sizing: border-box;
      font-family: $f_body;
      transition: $transition;
      -webkit-text-size-adjust: 100%;
      /* Prevent font scaling in Safari */
      user-select: text;
      /* Ensure text selection is enabled */
      -webkit-user-select: text;
      /* Ensure text selection is enabled in Safari */
      -moz-user-select: text;
      /* Ensure text selection is enabled in Firefox */
      -ms-user-select: text;
      /* Ensure text selection is enabled in IE/Edge */

      @include breakpoint(mobile) {
        font-size: 14px;
      }

      &:focus {
        border-color: $c_primary;
      }

      &::placeholder {
        color: #909090;
        opacity: 1;
      }

      &:disabled {
        color: $c_text;
        cursor: not-allowed;
        -webkit-text-fill-color: $c_text;
        opacity: 0.5;
      }
    }

    .search_field {
      input {
        background-image: url("../public/images/icon_search.svg");
        background-position: right 20px center;
        background-size: 25px;
        background-repeat: no-repeat;
        padding-right: 65px !important;
      }
    }

    input,
    .MuiOutlinedInput-root input.MuiAutocomplete-input {
      height: 50px;
      padding: 5px 15px;

      @include breakpoint(mobile) {
        height: 45px;
        padding: 5px 10px;
      }
    }

    select {
      height: 50px;
      padding: 0 60px 0 15px;

      @include breakpoint(ipadLandsacpe) {
        padding: 0 40px 0 15px;
      }

      @include breakpoint(mobile) {
        height: 45px;
        padding: 0 40px 0 10px;
      }
    }

    .MuiSelect-select {
      height: 50px !important;
      padding: 0 60px 0 15px !important;
      font-family: $f_body;
      display: inline-block;
      line-height: 50px;

      @include breakpoint(ipadLandsacpe) {
        padding: 0 40px 0 15px !important;
      }

      @include breakpoint(mobile) {
        padding: 0 40px 0 10px !important;
      }

      &[aria-expanded="true"] {
        border-color: $c_primary;
      }

      ~ fieldset {
        display: none;
      }

      ~ .MuiSvgIcon-root {
        right: 10px;
        font-size: 35px;
        color: $c_secondary;

        @include breakpoint(ipadLandsacpe) {
          font-size: 22px;
        }
      }
    }

    textarea {
      height: 100px;
      padding: 10px 15px;

      @include breakpoint(mobile) {
        padding: 10px 10px;
      }
    }

    .MuiFormGroup-root {
      &.flex {
        flex-direction: row;
        gap: 20px 50px;

        label {
          margin-bottom: 0;
        }
      }
    }

    .label_checkbox {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      width: auto;
      cursor: pointer;
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 20px;

        &.mb_0 {
          margin: var(--gap_y) 0 0;
        }
      }

      .MuiRadio-root {
        padding: 0;

        .MuiSvgIcon-root {
          fill: $c_white;

          &:nth-child(2) {
            transform: scale(1.7);
          }
        }

        &.Mui-checked {
          color: $c_secondary;

          .MuiSvgIcon-root {
            fill: currentColor;

            &:nth-child(2) {
              transform: scale(1);
            }
          }
        }
      }

      input {
        accent-color: $c_secondary;

        &[type="checkbox"] {
          width: 16px;
          height: 16px;
        }

        &[type="radio"] {
          width: 20px;
          height: 20px;
        }
      }

      > span {
        font-size: 14px;
        font-weight: 500;
        color: $c_white;
        font-family: $f_body;
      }
    }

    div.label_checkbox {
      label {
        color: $c_white;
        margin: 0;
        display: inline-flex;
        align-items: center;
        gap: 5px;

        > span {
          font-size: 14px;
          font-weight: 500;
          color: $c_white;
          font-family: $f_body;
        }
      }

      .MuiCheckbox-root {
        padding: 0;
        position: relative;
        top: 1px;

        &.Mui-checked {
          color: $c_secondary;
        }
      }
    }

    .upload_image {
      display: inline-flex;
      cursor: pointer;
      margin-bottom: 0 !important;

      input[type="file"] {
        position: absolute;
        inset: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        text-indent: -1000px;
      }

      &_holder {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        figure {
          height: 60px;
          width: 60px;
          border: 1px solid $c_primary;
          background-color: $c_white;
          border-radius: 50%;
          padding: 2px;
          transition: $transition;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        svg {
          width: 21px;
          height: 21px;
          border-radius: 50%;
          border: 1px solid $c_white;
          background: $c_primary;
          color: $c_white;
          padding: 2px;
          margin-top: -10.5px;
          transition: $transition;
        }
      }

      &:hover {
        .upload_image_holder {
          figure {
            border-color: $c_secondary;
          }

          svg {
            background-color: $c_secondary;
          }
        }
      }

      &.style_2 {
        position: relative;

        .upload_image_holder {
          figure {
            padding: 0;
            max-width: 240px;
            height: 330px;
            border: none;
            background-color: $c_white;
            border-radius: 10px;
            width: 100%;

            @include breakpoint(desktop) {
              height: 300px;
            }

            @include breakpoint(ipadLandsacpe) {
              height: 250px;
              max-width: 200px;
            }

            img {
              border-radius: 10px;

              @include breakpoint(mobile) {
                border-radius: 5px;
              }
            }
          }

          svg {
            width: 26px;
            height: 26px;
            border: 1px solid $c_primary;
            color: $c_black;
            padding: 4px;
            position: absolute;
            right: 10px;
            top: 10px;
            margin-top: 0;
          }
        }

        &:hover {
          .upload_image_holder {
            svg {
              color: $c_white;
              background-color: $c_secondary;
              border-color: $c_secondary;
            }
          }
        }
      }
    }

    .MuiInputBase-hiddenLabel {
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    .MuiFormControl-root.MuiTextField-root > .MuiInputBase-root {
      padding: 0;
    }

    .MuiNativeSelect-root {
      &::after,
      &::before {
        display: none;
      }

      select {
        padding-right: 50px !important;

        option {
          color: $c_black;
        }

        &:focus {
          border-radius: 8px;
          background: $c_white;
        }
      }

      .MuiNativeSelect-icon {
        right: 10px;
        font-size: 35px;
        color: $c_secondary;

        @include breakpoint(ipadLandsacpe) {
          font-size: 22px;
        }
      }
    }

    input:has(+ .eye_btn) {
      padding-right: 50px;

      @include breakpoint(mobile) {
        padding-right: 45px;
      }
    }

    .eye_btn {
      position: absolute;
      height: 50px;
      max-height: 50px;
      width: 50px;
      margin: 0;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(mobile) {
        height: 45px;
        max-height: 45px;
        width: 45px;
      }

      button {
        height: 80%;
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0 auto;

        svg {
          font-size: 20px;

          @include breakpoint(mobile) {
            font-size: 18px;
          }
        }
      }
    }
  }

  &_btn {
    margin-top: 30px;

    @include breakpoint(ipadLandsacpe) {
      margin-top: 25px;
    }
  }
}

.ut_spc,
.u_spc {
  padding-top: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-top: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-top: 50px;
  }
}

.ub_spc,
.u_spc {
  padding-bottom: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-bottom: 50px;
  }
}

.uht_spc,
.uh_spc {
  padding-top: 50px;

  @media screen and (max-width: 1199.98px) {
    padding-top: 40px;
  }
}

.uhb_spc,
.uh_spc {
  padding-bottom: 50px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 40px;
  }
}

.c {
  &_primary {
    color: $c_primary;
  }

  &_secondary {
    color: $c_secondary;
  }

  &_danger {
    color: $c_danger;
  }

  &_warning {
    color: $c_warning;
  }

  &_white {
    color: $c_white;
  }

  &_black {
    color: $c_white;
  }
}

.bg {
  &_primary {
    background-color: $c_primary;
  }

  &_secondary {
    background-color: $c_secondary;
  }

  &_white {
    background-color: $c_white;
  }

  &_black {
    background-color: $c_black;
  }
}

.gap_p,
.gap_m {
  --gap_x: 30px;
  --gap_y: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap_y) * -1);
  margin-left: calc(var(--gap_x) * -1);

  @include breakpoint(ipad) {
    --gap_x: 20px;
    --gap_y: 20px;
  }

  @include breakpoint(mobile) {
    --gap_x: 15px;
    --gap_y: 15px;
  }

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap_y);
  }

  &.gap_y_0 {
    --gap_y: 0px;
  }

  &.gap_x_0 {
    --gap_x: 0px;
  }

  &.aic {
    align-items: center;
  }
}

.gap_p > * {
  padding-left: var(--gap_x);
}

.gap_m > * {
  margin-left: var(--gap_x);
}

.d_block {
  display: block;
}

.w_auto {
  width: auto;
}

.text {
  &_start {
    text-align: start;
  }

  &_center {
    text-align: center;
  }

  &_end {
    text-align: right;
  }
}

.w {
  &_30 {
    width: 30%;

    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  &_50 {
    width: 50%;

    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  &_70 {
    width: 70%;

    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  &_100 {
    width: 100%;
  }
}

.p_relative {
  position: relative;
}

main {
  min-height: calc(100vh - (130px + 425px));

  @include breakpoint(ipadLandsacpe) {
    min-height: calc(100vh - (130px + 390.59px));
  }

  @include breakpoint(ipad) {
    min-height: calc(100vh - (130px + 546.59px));
  }

  @include breakpoint(mobile) {
    min-height: 1px;
  }
}

.side_container {
  width: calc(100% - (100% - 90%) / 2);
  max-width: calc(100% - (100% - 1170px) / 2);
}

.conta_iner {
  width: 90%;
  max-width: 1320px;
  margin-inline: auto;
}

@include breakpoint(mobile) {
  .conta_iner {
    width: 100%;
    padding: 0 15px;
  }
}

//phone Input
.react-tel-input .form-control:focus {
  box-shadow: none !important;
  background-color: transparent;
  // border: 1px solid $c_white !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.phone_drpdwn {
  li {
    span {
      color: black;

      &:hover {
        color: black;
      }
    }

    input {
      height: 42px;
    }
  }
}

.btn_flex {
  display: flex;
  align-items: center;
  gap: 30px;

  @include breakpoint(ipadLandsacpe) {
    gap: 20px;
  }

  @include breakpoint(mobile) {
    gap: 15px;
  }
}

// reset css end here

// Layout CSS
@import "../src/layout/Layout.scss";

.search_bar {
  .form_control {
    margin-bottom: 0;

    input {
      height: 66px;
      font-size: 18px;
      min-width: 460px;
      padding: 5px 66px 5px 20px;
      box-shadow: 0px 2px 36px #0000001a;
      border-radius: 8px;

      @include breakpoint(ipadLandsacpe) {
        height: 55px;
        font-size: 16px;
        min-width: 350px;
        padding: 5px 55px 5px 15px;
        border-radius: 5px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
        height: 50px;
      }

      @include breakpoint(mobileSmall) {
        min-width: 1px;
      }
    }

    .search_icon {
      position: absolute;
      right: 8px;
      top: 8px;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-color: $c_primary;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      max-height: initial;
      min-height: 1px;

      @include breakpoint(ipadLandsacpe) {
        right: 5px;
        top: 5px;
        width: 45px;
        height: 45px;
        border-radius: 5px;
      }

      @include breakpoint(mobile) {
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
      }

      svg {
        font-size: 26px;
        color: $c_white;

        @include breakpoint(ipadLandsacpe) {
          font-size: 24px;
        }
      }

      &:has(svg[data-testid="ClearIcon"]) {
        background-color: $c_danger;
        cursor: pointer;

        svg {
          color: $c_white !important;
        }
      }
    }
  }
}

.site_pagination {
  padding-bottom: 30px;

  @include breakpoint(mobile) {
    padding-bottom: 20px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 0;
      font-size: 22px;
      font-weight: 500;
      color: $c_primary;

      @include breakpoint(desktop) {
        font-size: 20px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 18px;
      }

      @include breakpoint(ipad) {
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
      }

      &:not(:last-child) {
        &::after {
          content: ">";
          color: #393939;
          display: inline-flex;
          margin: 0 8px;

          @include breakpoint(mobile) {
            margin: 0 5px;
          }
        }
      }

      a {
        color: #393939;
        display: inline-flex;

        &:hover {
          color: $c_primary;
          text-decoration: underline !important;
        }
      }
    }
  }
}

.s_head {
  margin-bottom: 50px;

  @include breakpoint(desktop) {
    margin-bottom: 40px;
  }

  @include breakpoint(ipad) {
    margin-bottom: 30px;
  }

  &:not(.f_width) {
    max-width: 965px;
    margin-inline: auto;
  }

  h2 {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 26px;
    line-height: 1.3;

    @include breakpoint(desktop) {
      font-size: 24px;
    }

    @include breakpoint(ipadLandsacpe) {
      font-size: 22px;
    }

    @include breakpoint(ipad) {
      font-size: 20px;
    }

    @include breakpoint(mobile) {
      font-size: 18px;
    }

    @include breakpoint(mobileSmall) {
      font-size: 16px;
    }
  }

  &.text_white {
    :is(h2, p) {
      color: $c_white;
    }
  }

  &_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    .right_s {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      .btn_rounded {
        border-radius: 8px;
        height: 66px;
        width: 66px;
        min-width: 66px;

        @include breakpoint(ipadLandsacpe) {
          border-radius: 5px;
          height: 55px;
          width: 55px;
          min-width: 55px;
        }

        @include breakpoint(mobileSmall) {
          border-radius: 5px;
          height: 50px;
          width: 50px;
          min-width: 50px;
        }

        svg {
          font-size: 35px;

          @include breakpoint(ipadLandsacpe) {
            font-size: 30px;
          }
        }
      }
    }
  }
}

.form_sc {
  .s_head {
    :is(h1, p) {
      color: #393939;
    }

    h1 {
      margin-bottom: 15px;

      @include breakpoint(mobile) {
        br {
          display: none;
        }
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  form {
    max-width: 1100px;
    margin-inline: auto;
  }

  .form {
    padding: 30px;
    background-color: $c_white;
    box-shadow: 0px 2px 56px #0000001a;
    border-radius: 30px;

    @include breakpoint(ipad) {
      padding: 25px 20px;
      border-radius: 10px;
    }

    @include breakpoint(mobile) {
      padding: 20px 15px;
      border-radius: 5px;
    }

    &_control {
      label {
        text-transform: uppercase;
      }

      :is(input, select, .MuiSelect-select, textarea):not(:focus) {
        border-color: #d3d3d3;
      }

      .checkbox_label {
        margin: 0;
        text-transform: none;

        .MuiCheckbox-root {
          padding: 0;
          position: relative;
          top: -2px;
          left: -2px;
          margin-right: 8px;

          input {
            height: 100%;
          }

          svg {
            height: 22px;
            width: 22px;

            path {
              fill: #393939;
            }
          }

          &.Mui-checked {
            svg {
              path {
                fill: $c_primary;
              }
            }
          }
        }

        .MuiFormControlLabel-label {
          color: $c_text;
          font-size: 18px;
          font-weight: 500;
          display: inline-flex;
          line-height: 1.5;
          font-style: normal;
          font-family: $f_body;

          @include breakpoint(ipad) {
            font-size: 16px;
          }

          @include breakpoint(mobile) {
            font-size: 14px;
          }
        }
      }

      p {
        a {
          color: $c_primary;
          text-decoration: underline !important;

          &:hover {
            text-decoration: none !important;
          }
        }
      }
    }
  }

  &_btn {
    margin-top: 50px;
    text-align: center;

    @include breakpoint(ipad) {
      margin-top: 40px;
    }

    @include breakpoint(mobile) {
      margin-top: 30px;
    }
  }
}

.custom_tabs {
  .custom_tabs_links {
    margin-bottom: 15px;
    min-height: 1px;

    .MuiTabs-scroller {
      padding: 20px 0;
    }

    .MuiTabs-flexContainer {
      display: inline-flex;
      box-shadow: 0px 2px 36px #0000001a;
      border-radius: 8px;
      padding: 0;
      gap: 5px;

      @include breakpoint(desktop) {
        box-shadow: 0px 2px 20px #0000001a;
        border-radius: 5px;
      }

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 70px;
        min-width: 170px;
        padding: 0 15px;
        font-size: 22px;
        font-weight: 700;
        color: $c_text;
        text-transform: capitalize;
        border-radius: 8px;
        line-height: normal;
        transition: $transition;
        font-family: $f_body;

        @include breakpoint(desktop) {
          min-height: 50px;
          min-width: 150px;
          font-size: 18px;
          border-radius: 5px;
        }

        @include breakpoint(mobile) {
          min-height: 45px;
          min-width: 120px;
          font-size: 16px;
        }

        @include breakpoint(mobileSmall) {
          font-size: 14px;
        }

        &:hover {
          color: $c_black;
          background-color: rgb(32 78 51 / 10%);
        }

        &.Mui-selected {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }

  &1 {
    .custom_tabs_links {
      margin-bottom: 0;

      .MuiTabs-scroller {
        padding: 0;
      }

      .MuiTabs-flexContainer {
        display: inline-flex;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        gap: 30px;

        @include breakpoint(mobile) {
          gap: 20px;
        }

        + .MuiTabs-indicator {
          display: none;
        }

        button {
          font-size: 18px;
          min-height: 1px;
          min-width: 1px;
          padding: 0 0 5px;
          color: #b4b4b4;
          border-radius: 0;
          border-bottom: 4px solid transparent;
          background-color: transparent;

          @include breakpoint(ipad) {
            font-size: 16px;
          }

          @include breakpoint(mobileSmall) {
            font-size: 14px;
          }

          &:hover {
            color: $c_black;
            background-color: transparent;
          }

          &.Mui-selected {
            color: $c_black;
            border-color: $c_primary;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.custom_tabs2 {
  .custom_tabs_links {
    margin-bottom: 15px;
    min-height: 1px;

    .MuiTabs-scroller {
      padding: 0;
    }

    .MuiTabs-flexContainer {
      display: inline-flex;
      justify-content: space-between;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
      gap: 30px;
      width: 100%;

      + .MuiTabs-indicator {
        display: none;
      }

      button {
        min-height: 1px;
        min-width: 1px;
        padding: 0;
        font-size: 20px;
        font-weight: 700;
        color: $c_black;
        text-transform: uppercase;
        border-radius: 0;
        line-height: normal;
        transition: $transition;
        font-family: $f_body;

        @include breakpoint(desktop) {
          font-size: 18px;
        }

        @include breakpoint(ipad) {
          font-size: 16px;
        }

        &:hover {
          color: $c_primary;
          background-color: transparent;
        }

        &.Mui-selected {
          color: $c_primary;
          background-color: transparent;
        }
      }
    }
  }

  [role="tabpanel"] {
    > .MuiBox-root {
      padding: 0;
    }
  }
}

.similar_sc {
  .hd_3 {
    margin-bottom: 30px;

    @include breakpoint(desktop) {
      margin-bottom: 25px;
    }

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 20px;
    }
  }

  &:has(.gap_m:empty) {
    display: none;
  }
}

body {
  .MuiSwitch-root {
    padding: 0;
    width: 37px;
    height: 22px;

    .MuiSwitch-switchBase {
      padding: 0;
      color: #f3f3f3;
      height: 22px;
      left: 2px;

      .MuiSwitch-thumb {
        width: 18px;
        height: 18px;
      }

      + .MuiSwitch-track {
        opacity: 1;
        border-radius: 15px;
        background-color: #c9c9c9;
      }

      &.Mui-checked {
        color: #e8f5e9;
        transform: translateX(15px);

        + .MuiSwitch-track {
          background-color: $c_primary;
        }
      }
    }
  }
}

.coupon_card_dtl {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  background: #def1e3;
  min-height: 180px;
  padding: 15px 0;
  border-radius: 15px;
  max-width: 435px;

  @include breakpoint(ipad) {
    min-height: 140px;
    padding: 10px 0;
    border-radius: 10px;
    max-width: 315px;
  }

  > figure,
  .coupon_btn {
    min-width: 90px;
    max-width: 90px;
    position: relative;

    @include breakpoint(ipad) {
      min-width: 60px;
      max-width: 60px;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      inset: -22px -9px auto auto;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $c_white;

      @include breakpoint(ipad) {
        inset: -17px -7px auto auto;
        width: 12px;
        height: 12px;
      }
    }

    &::before {
      bottom: -22px;
      top: unset;

      @include breakpoint(ipad) {
        bottom: -17px;
        top: unset;
      }
    }
  }

  .coupon_dtl {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-inline: 20px;
    width: calc(100% - 180px);
    border-right: 1px dashed rgb(13 66 134 / 25%);
    border-left: 1px dashed rgb(13 66 134 / 25%);

    @include breakpoint(ipad) {
      padding-inline: 10px;
      width: calc(100% - 120px);
    }

    .uploaded_coupon {
      width: 100%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint(ipad) {
        height: 120px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    h2 {
      font-size: 32px;
      color: #393939;
      margin-bottom: 10px;

      @include breakpoint(ipad) {
        font-size: 24px;
        margin-bottom: 5px;
      }
    }

    p {
      color: #393939;
      font-weight: 500;
      font-size: 16px;

      @include breakpoint(ipad) {
        font-size: 12px;
      }
    }
  }

  > figure {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-inline: 10px;
    position: relative;

    span {
      writing-mode: vertical-lr;
      font-size: 14px;
      font-weight: 600;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(-1);
      white-space: nowrap;
      background: #fff;
      line-height: 1;
      padding: 5px 2px;

      @include breakpoint(ipad) {
        font-size: 12px;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      margin-inline: auto;
    }
  }

  .coupon_btn {
    display: flex;
    justify-content: center;
    padding-inline: 10px;

    &::after,
    &::before {
      right: unset;
      left: -9px;

      @include breakpoint(ipad) {
        left: -7px;
      }
    }

    .btn {
      font-size: 26px;
      min-width: 100%;
      min-height: 1px;
      padding: 5px;
      border-radius: 40px;
      font-weight: 500;
      pointer-events: none;
      white-space: nowrap;

      @include breakpoint(ipad) {
        font-size: 20px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 18px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
      }

      span {
        transform: rotate(90deg) scale(-1);
      }
    }
  }
}

.sitePagination {
  margin-top: 50px;

  @include breakpoint(ipad) {
    margin-top: 40px;
  }

  @include breakpoint(mobile) {
    margin-top: 30px;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    li {
      margin: 0;

      &:last-child,
      &:first-child {
        a {
          background-color: #a3a3a3;
        }
      }

      a {
        width: 45px;
        height: 45px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 600;
        color: #1d1d1d;

        @include breakpoint(ipadLandsacpe) {
          width: 40px;
          height: 40px;
          font-size: 14px;
        }

        @include breakpoint(mobile) {
          width: 30px;
          height: 30px;
          font-size: 12px;
        }

        svg {
          color: $c_white;
          font-size: 16px;

          @include breakpoint(mobile) {
            font-size: 12px;
          }
        }

        &:hover,
        &.active {
          color: $c_white;
          background-color: $c_primary;
        }
      }
    }
  }
}

.pagination {
  margin-top: 50px;

  @include breakpoint(ipad) {
    margin-top: 40px;
  }

  @include breakpoint(mobile) {
    margin-top: 30px;
  }

  .pages {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .prevbtn {
      width: 45px;
      height: 45px;
      color: #1d1d1d;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $c_white;
      border: 1px solid #eaefe8;
      transition: $transition;
      cursor: pointer;

      @include breakpoint(ipadLandsacpe) {
        width: 40px;
        height: 40px;
      }

      @include breakpoint(mobile) {
        width: 30px;
        height: 30px;
      }

      svg {
        font-size: 18px;

        @include breakpoint(mobile) {
          font-size: 12px;
        }
      }

      &:hover {
        background-color: #eaefe8;
      }
    }

    .count {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      gap: 5px;

      .actv,
      .inActv {
        width: 45px;
        height: 45px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 600;
        border: 1px solid #eaefe8;
        transition: $transition;
        cursor: pointer;

        @include breakpoint(ipadLandsacpe) {
          width: 40px;
          height: 40px;
          font-size: 14px;
        }

        @include breakpoint(mobile) {
          width: 30px;
          height: 30px;
          font-size: 12px;
        }
      }

      .inActv {
        color: #1d1d1d;
        background-color: $c_white;

        &:hover {
          background-color: #eaefe8;
        }
      }

      .actv {
        color: $c_white;
        border-color: $c_primary;
        background-color: $c_primary;
        pointer-events: none;
      }
    }
  }
}

.filter_sort {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-block: 0 25px;
  gap: 30px;

  @include breakpoint(desktop) {
    margin-block: 0 20px;
    gap: 20px;
  }

  @include breakpoint(ipad) {
    align-items: flex-start;
  }

  @include breakpoint(mobile) {
    flex-direction: column;
    margin-block: 0 30px;
  }

  .form {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 0;
    gap: 10px 30px;

    @include breakpoint(desktop) {
      gap: 10px 20px;
    }

    @include breakpoint(ipad) {
      flex-direction: column;
    }

    @include breakpoint(mobileSmall) {
      width: 100%;
      gap: 20px;
    }

    &_control {
      display: inline-flex;
      align-items: center;
      margin-block: 0;
      gap: 10px;

      @include breakpoint(desktop) {
        gap: 5px;
      }

      @include breakpoint(mobileSmall) {
        flex-wrap: wrap;
      }

      > * {
        width: max-content;

        &:not(label) {
          @include breakpoint(mobileSmall) {
            width: auto;
            flex-grow: 1;
          }
        }
      }

      label {
        font-size: 14px;
        font-weight: 800 !important;
        white-space: nowrap;
        margin: 0;

        @include breakpoint(ipad) {
          min-width: 60px;
        }

        @include breakpoint(mobileSmall) {
          width: 100%;
        }
      }

      :is(input, select, .MuiSelect-select) {
        font-size: 14px;
        height: 40px !important;
        padding: 5px 10px;
        max-width: 130px;
        min-width: 130px;
        width: 100%;
        font-weight: 500;
        line-height: 40px;
        border-color: #d3d3d3;

        @include breakpoint(ipad) {
          max-width: 150px;
          min-width: 150px;
        }

        @include breakpoint(mobileSmall) {
          max-width: 100%;
          min-width: 100%;
        }
      }

      .MuiSelect-select {
        padding: 0 25px 0 10px !important;

        ~ .MuiSvgIcon-root {
          right: 4px;
          font-size: 20px;
        }
      }
    }
  }

  .view_type {
    display: inline-flex;
    align-items: center;
    gap: 10px;

    label {
      font-size: 14px;
      font-weight: 800 !important;
      white-space: nowrap;
      margin: 0;
    }

    .btn_flex {
      gap: 5px;

      .btn_rounded {
        height: 40px;
        width: 40px;
        border-radius: 5px;

        svg {
          font-size: 24px;
        }

        &.active {
          pointer-events: none;
        }

        &:not(.active) {
          background-color: #d3d3d3;

          &:hover {
            background-color: #b7b3b3;
          }
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  top: 50%;
  margin: 0;
  width: calc(var(--space_y) - 15px);
  height: calc(var(--space_y) - 15px);
  display: flex;
  color: $c_white;
  align-items: center;
  justify-content: center;
  background-color: $c_primary;
  transform: translate(0%, -50%);
  transition: $transition;
  border-radius: 50%;
  z-index: 3;

  @include breakpoint(mobile) {
    width: calc(var(--space_y) - 10px);
    height: calc(var(--space_y) - 10px);
  }

  &.swiper-button-lock {
    display: none;
  }

  &::after {
    width: 20px;
    color: $c_white;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(mobile) {
      width: 18px;
      font-size: 18px;
    }
  }

  &:hover {
    transform: translate(0%, -50%) scale(1.05);
  }
}

.swiper-button-prev {
  left: 5px;
}

.swiper-button-next {
  right: 5px;
}

.inline_arrow {
  --space_y: 65px;
  padding-inline: var(--space_y);
  margin-inline: -5px;
  position: relative;

  @include breakpoint(mobile) {
    --space_y: 50px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: var(--space_y);
    background-color: $c_secondary;
    z-index: 2;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  &:has(.swiper-button-lock) {
    --space_y: 0;
  }
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;
  margin: 0 auto;

  figure {
    width: 50px;
    height: 50px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.text_field p,
.err_msg {
  color: red !important;
  font-size: 12px !important;
  margin: 3px 0 0 !important;
  font-weight: 500;
}

.password_page {
  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    position: absolute;
    right: 20px;
    top: 24px;
  }

  .form_control.form_control_bg {
    :is(input, select, .MuiSelect-select, textarea) {
      padding-right: 40px;
    }
  }
}

.MuiModal-root {
  display: flex;
  overflow: auto;
  padding: 15px 0;
}

.modal {
  &_body {
    width: calc(100% - 30px);
    max-width: 530px;
    margin: auto;
    position: relative;

    @include breakpoint(desktop) {
      max-width: 450px;
    }

    @include breakpoint(mobile) {
      max-width: 400px;
    }

    .close {
      color: $c_black;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 35px;
      cursor: pointer;
      transition: $transition;
      z-index: 1;

      @include breakpoint(desktop) {
        font-size: 25px;
      }

      &:hover {
        color: #ff0000;
      }
    }
  }

  &_title {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 30px;

    @include breakpoint(ipadLandsacpe) {
      font-size: 22px;
    }

    @include breakpoint(mobile) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  &_content {
    background: $c_white;
    box-shadow: 0px 2px 56px #0000001a;
    border-radius: 10px;
    position: relative;
    text-align: center;
  }
}

.metaMask_box {
  padding: 80px 50px;
  border-radius: 30px;

  @include breakpoint(desktop) {
    padding: 60px 20px;
  }

  @include breakpoint(ipadLandsacpe) {
    padding: 60px 30px;
    border-radius: 20px;
  }

  @include breakpoint(mobile) {
    padding: 50px 15px;
    border-radius: 10px;
  }

  &_title {
    font-size: 30px;

    @include breakpoint(desktop) {
      font-size: 28px;
    }

    @include breakpoint(ipadLandsacpe) {
      font-size: 25px;
    }

    @include breakpoint(mobile) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 25px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 20px;

    @include breakpoint(desktop) {
      font-size: 24px;
    }

    @include breakpoint(ipadLandsacpe) {
      font-size: 22px;
    }

    @include breakpoint(mobile) {
      font-size: 20px;
      margin-top: 20px;
    }
  }

  figure {
    display: flex;
    justify-content: center;

    img {
      height: 195px;

      @include breakpoint(desktop) {
        height: 180px;
      }

      @include breakpoint(ipadLandsacpe) {
        height: 150px;
      }

      @include breakpoint(mobile) {
        height: 120px;
      }
    }
  }

  .link_box {
    display: flex;
    align-items: center;

    p {
      font-size: 24px;
      font-weight: 800;
      display: flex;
      align-items: center;
      border-radius: 16px;
      justify-content: space-between;
      background-color: #f2f2f2;
      gap: 20px;
      width: calc(100% - 20px);
      min-height: 57px;
      margin: 0;
      padding: 5px 20px;
      border-radius: 5px 0 0 5px;

      @include breakpoint(desktop) {
        font-size: 22px;
        min-height: 50px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 18px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
        width: calc(100% - 120px);
        padding: 5px 10px;
        gap: 0px;
      }

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
      }

      figure {
        display: inline-flex;
        cursor: pointer;

        img {
          height: 30px;

          @include breakpoint(ipadLandsacpe) {
            height: 25px;
          }

          @include breakpoint(mobile) {
            height: 20px;
          }
        }
      }
    }

    .btn {
      min-width: 1px;
      border-radius: 0 5px 5px 0;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1px;
      min-width: 150px;
      padding: 0 10px;

      @include breakpoint(mobile) {
        font-size: 14px;
        letter-spacing: 0.5px;
        min-width: 120px;
      }
    }
  }
}

.logout_modal {
  .modal {
    &_title {
      text-align: center;
      margin-bottom: 20px;

      &.text_left {
        text-align: left;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .short_dsc {
      text-align: left;
      margin-bottom: 30px;
    }

    &_content {
      padding: 65px 40px;

      @include breakpoint(desktop) {
        padding: 55px 40px;
      }

      @include breakpoint(mobile) {
        padding: 55px 20px;
      }

      .btn_flex {
        justify-content: center;
        gap: 20px;

        @include breakpoint(mobile) {
          gap: 15px;
        }

        .btn {
          font-size: 16px;
          min-height: 45px;
          padding: 2px 20px;
          min-width: 100px;
          text-transform: uppercase;
          letter-spacing: 1px;

          @include breakpoint(mobile) {
            font-size: 14px;
            min-width: 80px;
          }

          &.br {
            color: $c_primary;
            background-color: $c_white;
            border-color: $c_primary;
          }
        }
      }

      .form_control
        :is(input, select, .MuiSelect-select, textarea):not(:focus) {
        border-color: #d3d3d3;
      }
    }
  }
}

.no_date_sc {
  text-align: center;
  max-width: 500px;
  margin-inline: auto;

  @include breakpoint(ipadLandsacpe) {
    max-width: 400px;
  }

  figure {
    display: inline-flex;
    align-items: center;
    margin-bottom: 35px;

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 20px;
    }

    img {
      max-width: 125px;

      @include breakpoint(ipadLandsacpe) {
        max-width: 80px;
      }

      @include breakpoint(mobile) {
        max-width: 60px;
      }
    }
  }

  h2 {
    margin-bottom: 20px;

    @include breakpoint(ipadLandsacpe) {
      margin-bottom: 15px;
    }

    @include breakpoint(mobile) {
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 10px;
    }
  }

  p {
    color: #707070;
    font-weight: 500;
    margin-bottom: 20px;

    @include breakpoint(mobile) {
      margin-bottom: 15px;
    }
  }

  .btn {
    min-width: 1px;
    font-size: 16px;
    min-height: 40px;

    @include breakpoint(mobile) {
      font-size: 14px;
    }
  }
}

.prfl_autocmplt {
  .MuiOutlinedInput-root input.MuiAutocomplete-input {
    padding-right: 55px;

    @include breakpoint(ipadLandsacpe) {
      padding-right: 40px;
    }
  }

  .MuiAutocomplete-endAdornment {
    // top: calc(50% - 17.5px);
    right: 10px !important;

    @include breakpoint(ipadLandsacpe) {
      // top: calc(50% - 12px);
    }

    button {
      padding: 0;
      margin: 0;

      svg {
        color: $c_secondary;
        font-size: 35px;

        @include breakpoint(ipadLandsacpe) {
          font-size: 22px;
        }

        &[data-testid="CloseIcon"] {
          font-size: 24px;
          color: $c_danger;

          @include breakpoint(ipadLandsacpe) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon {
    .MuiOutlinedInput-root {
      padding-right: 0 !important;
    }

    .MuiOutlinedInput-root input.MuiAutocomplete-input {
      padding-right: 90px;

      @include breakpoint(ipadLandsacpe) {
        padding-right: 65px;
      }
    }
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    display: none;
  }
}

.MuiAutocomplete-popper {
  li {
    padding: 5px 10px !important;
    min-height: 1px !important;
    font-size: 16px !important;
    font-weight: 500 !important;

    @include breakpoint(mobile) {
      font-size: 14px !important;
    }

    small {
      font-weight: 400 !important;
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 0 !important;
    }
  }
}

.clickDisable {
  pointer-events: all !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}

.react-tel-input {
  .country-list {
    > li {
      margin: 0;
    }
    .search {
      padding: 10px;
      .search-box {
        height: 40px;
      }
    }
    .country {
      padding: 8px 10px 8px 40px;
      font-size: 14px;
      font-weight: 500;
    }
    .flag {
      margin-right: 0px;
      margin-top: 0;
      left: 10px;
      top: 6px;
    }
  }
}

.desc_txt {
  text-align: left !important;
  margin: 10px 0px !important;
}

.mpVendors_hero {
  position: relative;
  z-index: 1;
}
.mpVendors_hero::before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(90deg, #081630 25%, transparent);
  z-index: -1;
}
@media screen and (max-width: 767.98px) {
  .mpVendors_hero::before {
    background: #081630;
    opacity: 0.7;
  }
}

.sec_head {
  @include d-flex(center, space-between);
  // border-bottom: 1px solid $light_gray;
  padding-bottom: 14px;
  margin-bottom: 26px;

  .btn {
    min-width: 84px;
    min-height: 26px;
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
  }

  .btn_grp {
    @include d-flex(center, flex-start, 20px);

  }
}

.popoverTrigger {
  cursor: pointer;
  position: fixed;
  right: 24px;
  bottom: 24px;
  background-color: #4CAF50;
  padding: 12px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0 4px 12px rgba(76, 175, 80, 0.3);
  z-index: 999;
  transition: all 0.3s ease;
  
  display: flex;
  align-items: center;
  gap: 8px;
  
  span {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  &:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(76, 175, 80, 0.4);
  }
  
  &:active {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.custom-popup .mapboxgl-popup-content {
  padding: 0 !important;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-popup .mapboxgl-popup-tip {
  border-top-color: white !important;
  filter: drop-shadow(0 2px 2px rgba(0,0,0,0.1));
}

.map-controls {
  position: absolute;
  right: 50px;
  top: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.filter-button {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  transition: all 0.3s ease;

  &--repair {
    border: 2px solid #4CAF50;
    background-color: transparent;
    color: #4CAF50;

    &.active {
      background-color: #4CAF50;
      color: white;
    }
  }

  &--trade-in {
    border: 2px solid #2196F3;
    background-color: transparent;
    color: #2196F3;

    &.active {
      background-color: #2196F3;
      color: white;
    }
  }

  &--recycling {
    border: 2px solid #ee9a08;
    background-color: transparent;
    color: #ee9a08;

    &.active {
      background-color: #ee9a08;
      color: white;
    }
  }

  &--near-me {
    border: 2px solid #000;
    background-color: transparent;
    color: #000;

    &.active {
      background-color: #000;
      color: white;
    }
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
  }
}

.location-popup {
  min-width: 280px;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  padding-right: 32px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  position: relative;

  &__address {
    font-size: 15px;
    font-weight: 500;
    color: #1a1a1a;
    margin-bottom: 12px;
  }

  &__ewaste {
    margin-bottom: 12px;

    &-title {
      font-size: 14px;
      color: #666;
      margin-bottom: 4px;
    }

    &-content {
      font-size: 14px;
      color: #333;
    }
  }

  &__tags {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
  }

  &__direction {
    font-size: 14px;
    padding: 4px 10px;
    border-radius: 16px;
    background-color: #3c1dea;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      background-color: #3c1dea;
      opacity: 0.8;
    }
  }
}

.location-tag {
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 16px;

  &--repair {
    background-color: #4CAF5015;
    color: #4CAF50;
    border: 1px solid #4CAF5030;
  }

  &--trade-in {
    background-color: #2196F315;
    color: #2196F3;
    border: 1px solid #2196F330;
  }

  &--recycling {
    background-color: #a6e41515;
    color: #ee9a08;
    border: 1px solid #a6e41530;
  }
}

.coupon_card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f5e9;
  gap: 16px;
  height: 280px;
  padding: 20px;
  cursor: pointer;
  

  .coupon_card_dtl {
    background: #DEF1E3;
    min-height: 160px;
    padding: 10px 0;
    border-radius: 14px;

    .coupon_dtl {
      width: 54%;
      padding-inline: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-right: 1px dashed rgb(13 66 134 / 25%);
      border-left: 1px dashed rgb(13 66 134 / 25%);
    }

    >figure,
    .coupon_btn {
      display: flex;
      justify-content: center;
      padding-inline: 10px;

      &::after,
      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #f3f5e9;
      }

      .btn {
        min-width: 40px;
        min-height: unset;
        writing-mode: vertical-rl;
        padding: 5px;
        border-radius: 14px;
        transform: scale(-1);
      }
    }
  }

  .coupon_description {
    margin: 15px 0;
    text-align: center;
    
    p {
      color: #1a1a1a;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
  }

  .add_to_cart {
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    .btn {
      height: 40px;
    }

    .btn_primary {
      min-width: 150px;
    }
    
    .quantity {
      margin-top: 10px;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
