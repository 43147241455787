@import "../../variable.scss";

.getInTouch_sc {
    >figure {
        height: 340px;
        display: flex;

        @include breakpoint(ipadLandsacpe) {
            height: 250px;
        }

        @include breakpoint(ipad) {
            height: 180px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .inner {
        .s_head {
            h2 {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }

        .gap_p {
            @include breakpoint(mobile) {
                --gap_x: 0px;
            }
        }

        .single {
            width: calc(100% / 3);
            text-align: center;

            @include breakpoint(mobile) {
                width: calc(100% / 1);
                border: 1px solid #DBDBDB;
                border-radius: 5px;
                padding: 20px;
            }

            figure {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                margin-bottom: 15px;

                @include breakpoint(ipadLandsacpe) {
                    height: 50px;
                    margin-bottom: 20px;
                }

                @include breakpoint(ipad) {
                    height: 40px;
                    width: 40px;
                    margin-inline: auto;
                }

                img {
                    max-height: 100%;
                }
            }

            h3 {
                font-size: 18px;
                text-transform: uppercase;
                margin-bottom: 10px;

                @include breakpoint(ipad) {
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;

                @include breakpoint(ipad) {
                    font-size: 14px;
                    font-weight: 500;
                }

                a {
                    color: $c_text;

                    &:hover {
                        color: $c_primary;
                    }
                }
            }
        }
    }
}

.page_Contact {
    .form_sc {
        .form {
            @include breakpoint(ipad) {
                padding: 25px 20px 5px;
            }

            @include breakpoint(mobile) {
                padding: 20px 15px 5px;
            }
        }
    }
}