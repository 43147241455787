@import "../../variable.scss";

.subcategory_listing {
  &:has(.gap_m:empty) {
    display: none;
  }

  .subcategory_listing_single {
    width: calc(100% / 5 - var(--gap_y));
    position: relative;

    @include breakpoint(desktop) {
      width: calc(100% / 4 - var(--gap_y));
    }

    @include breakpoint(ipadLandsacpe) {
      width: calc(100% / 3 - var(--gap_y));
    }

    @include breakpoint(mobile) {
      width: calc(100% / 2 - var(--gap_y));
    }

    &.claimed {
      &::before {
        content: "Coupon Claimed";
        position: absolute;
        left: 10px;
        top: 10px;
        font-size: 12px;
        font-weight: 600;
        background-color: $c_primary;
        color: $c_white;
        z-index: 1;
        border-radius: 3px;
        padding: 3px 10px;

        @include breakpoint(mobileSmall) {
          font-size: 11px;
          padding: 3px 8px;
          left: 5px;
          top: 5px;
        }
      }
    }
  }

  .gap_m {
    --gap_x: 20px;
    --gap_y: 20px;

    @include breakpoint(mobile) {
      --gap_x: 15px;
      --gap_y: 15px;
    }

    @include breakpoint(mobileSmall) {
      --gap_x: 10px;
      --gap_y: 10px;
    }
  }

  &_single {
    padding-bottom: 20px;
    box-shadow: 0px 2px 56px #0000001a;
    background-color: $c_white;
    transition: $transition;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    @include breakpoint(ipadLandsacpe) {
      border-radius: 15px;
    }

    @include breakpoint(ipad) {
      padding-bottom: 15px;
      border-radius: 10px;
    }

    @include breakpoint(mobile) {
      border-radius: 5px;
    }

    @include breakpoint(mobileSmall) {
      padding-bottom: 12px;
    }

    figure {
      width: 100%;
      display: flex;
      aspect-ratio: 1/1;
      transition: $transition;
      background-color: $c_white;
      margin-bottom: 15px;
      overflow: hidden;

      @include breakpoint(ipad) {
        margin-bottom: 12px;
      }

      @include breakpoint(mobileSmall) {
        margin-bottom: 14px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    :is(h3, p) {
      padding-inline: 18px;
      transition: $transition;

      @include breakpoint(ipad) {
        padding-inline: 15px;
      }

      @include breakpoint(mobileSmall) {
        padding-inline: 10px;
      }
    }

    h3 {
      font-size: 20px;

      @include breakpoint(ipad) {
        font-size: 18px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
      }

      @include breakpoint(mobileSmall) {
        font-size: 14px;
      }
    }

    p {
      font-size: 16px;
      @include content_limit(1);
      margin-top: 5px;

      @include breakpoint(ipad) {
        font-size: 14px;
        margin-top: 2px;
      }

      @include breakpoint(mobileSmall) {
        font-size: 12px;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .tag {
      float: right;
      font-size: 14px;
      font-weight: 500;
      border-radius: 3px;
      align-items: center;
      display: inline-flex;
      background: $c_white;
      margin: 0 10px -10px 0;
      padding: 4px 6px;
      line-height: 1;
      gap: 6px;

      @include breakpoint(ipadLandsacpe) {
        right: 5px;
        bottom: 5px;
        padding: 4px 6px;
        gap: 5px;
      }

      @include breakpoint(ipad) {
        font-size: 12px;
        padding: 3px 5px;
        margin: 0 5px -10px 0;
      }

      @include breakpoint(mobileSmall) {
        font-size: 10px;
        padding: 2px 4px;
        margin: 2px 4px -8px 0;
        gap: 2px;
      }

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        display: inline-flex;
        background-color: $c_text;
        position: relative;
        top: -1px;

        @include breakpoint(ipad) {
          width: 5px;
          height: 5px;
          top: 0px;
        }

        @include breakpoint(mobileSmall) {
          width: 3px;
          height: 3px;
        }
      }

      &.c_primary {
        &::before {
          background-color: $c_primary;
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $c_primary;

      figure {
        img {
          transform: scale(1.05);
        }
      }

      :is(h3, p) {
        color: $c_white;
      }
    }
  }

  &.list_view {
    .gap_m {
      @include breakpoint(mobile) {
        --gap_y: 10px;
      }
    }

    .subcategory_listing_single {
      --img_height: 150px;
      --img_space: 15px;
      width: calc(50% - var(--gap_y));
      min-height: calc(var(--img_height) + (var(--img_space) * 2));
      position: relative;
      padding: var(--img_space) var(--img_space) var(--img_space)
        calc(var(--img_height) + (var(--img_space) * 2));
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: none;

      @include breakpoint(ipad) {
        --img_height: 100px;
        --img_space: 10px;
      }

      @include breakpoint(mobile) {
        --img_height: 80px;
        width: calc(100% - var(--gap_y));
      }

      @include breakpoint(mobileSmall) {
        --img_space: 5px;
        padding-left: calc(var(--img_height) + var(--img_space) * 3);
      }

      figure {
        position: absolute;
        left: var(--img_space);
        top: var(--img_space);
        width: var(--img_height);
        border-radius: 5px;
        margin-bottom: 0;
        transition: none;

        img {
          transition: none;
        }
      }

      :is(h3, p) {
        padding-inline: 0;
      }
    }
  }
}
