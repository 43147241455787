@import "../../variable.scss";

.news {
  &_single {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3 - var(--gap_x)) !important;
    cursor: pointer;

    @include breakpoint(ipad) {
      width: calc(100% / 2 - var(--gap_x)) !important;
    }

    @include breakpoint(mobile) {
      width: calc(100% / 1 - var(--gap_x)) !important;
    }

    figure {
      width: 100%;
      aspect-ratio: 1/0.67;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    .news_info {
      display: flex;
      align-items: flex-end;
      margin-block: -15px 15px;
      position: relative;
      z-index: 1;
      gap: 15px;

      .date {
        color: $c-white;
        font-size: 10px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: $c_primary;
        text-align: center;
        padding: 5px 10px;
        line-height: 1.2;

        strong {
          font-size: 12px;
          font-weight: 800;
        }
      }

      .views {
        color: #7b7b7b;
        font-size: 12px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        line-height: 1.2;
        gap: 3px;

        svg {
          font-size: 14px;
          color: $c_primary;
        }
      }
    }

    h3 {
      font-weight: 800;
      margin-bottom: 15px;
      transition: $transition;

      @include breakpoint(ipad) {
        margin-bottom: 10px;
      }
    }

    > p {
      font-size: 16px;
      font-weight: 600;

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }

    .btn_link {
      font-size: 14px;
      font-weight: 600;
      margin-top: auto;
      gap: 2px;

      svg {
        color: #393939;
        font-size: 14px;
      }
    }

    &:hover {
      figure {
        img {
          transform: scale(1.05);
        }
      }

      h3 {
        color: $c_primary;
      }

      .btn_link {
        text-decoration: underline !important;
      }
    }
  }

  &_listing {
    .gap_m {
      @include breakpoint(mobile) {
        --gap_y: 30px;
      }
    }

    .news_single {
      h3 {
        @include content_limit(2);
      }

      > p {
        margin-bottom: 25px;
        @include content_limit(3);

        @include breakpoint(ipad) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.newsBanner_sc {
  padding: 30px 0;
  display: flex;
  min-height: 228px;
  align-items: center;
  background: transparent linear-gradient(90deg, #19486a 0%, #030914 100%) 0% 0%
    no-repeat padding-box;

  @include breakpoint(ipadLandsacpe) {
    min-height: 150px;
  }

  @include breakpoint(mobile) {
    min-height: 120px;
  }

  h1 {
    font-size: 40px;

    @include breakpoint(ipadLandsacpe) {
      font-size: 36px;
    }

    @include breakpoint(ipad) {
      font-size: 32px;
    }
  }

  .site_pagination {
    margin-top: 15px;
    padding-bottom: 0;

    ul {
      li {
        &:not(:last-child)::after {
          color: $c_white;
        }

        a {
          color: $c_white;
        }
      }
    }
  }
}

.newsGrid_sc {
  .left_s {
    width: 53.5%;

    @include breakpoint(ipadLandsacpe) {
      width: 60%;
    }

    @include breakpoint(mobile) {
      width: 100%;
    }
  }

  .right_s {
    width: 46.5%;

    @include breakpoint(ipadLandsacpe) {
      width: 40%;
    }

    @include breakpoint(mobile) {
      width: 100%;
      display: none;
    }
  }

  .news {
    &_listing {
      .gap_m {
        --gap_y: 45px;

        @include breakpoint(mobile) {
          --gap_y: 30px;
        }
      }
    }

    &_single {
      width: calc(100% - var(--gap_x)) !important;

      figure {
        aspect-ratio: 1/0.65;
      }

      h3 {
        font-size: 30px;

        @include breakpoint(desktop) {
          font-size: 28px;
        }

        @include breakpoint(ipadLandsacpe) {
          font-size: 26px;
        }

        @include breakpoint(ipad) {
          font-size: 25px;
        }

        @include breakpoint(mobile) {
          font-size: 22px;
        }
      }

      .news_info {
        .date {
          font-size: 12px;

          strong {
            font-size: 20px;
          }
        }

        .views {
          margin-bottom: 10px;
        }
      }
    }

    &List_detail {
      .news_single {
        cursor: auto;
        width: 100% !important;

        figure {
          height: auto;
          width: 100%;
          display: flex;

          img {
            width: 100%;
          }
        }

        &:hover {
          figure {
            img {
              transform: none;
            }
          }

          h3 {
            color: $c_text;
          }
        }
      }
    }
  }

  .news_aside {
    position: sticky;
    top: 80px;

    .single {
      padding: 30px;
      background-color: #eaefe8;

      @include breakpoint(ipadLandsacpe) {
        padding: 20px;
      }

      @include breakpoint(ipad) {
        padding: 15px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

.newsRecent {
  &_listing {
    max-height: calc(100vh - 308px);
    padding-right: 10px;
    margin-right: -10px;
    overflow: auto;
  }

  &_single {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    gap: 15px;

    @include breakpoint(ipad) {
      gap: 10px;
    }

    figure {
      min-width: 76px;
      max-width: 76px;
      height: 51px;
      overflow: hidden;
      background-color: $c_white;

      @include breakpoint(ipad) {
        min-width: 66px;
        max-width: 66px;
        height: 44px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &:not(:last-child) {
      margin-bottom: 25px;

      @include breakpoint(ipad) {
        margin-bottom: 20px;
      }
    }

    &:hover {
      .newsRecent_info h3 {
        color: $c_primary;
      }
    }
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .date {
      color: #8a8a8a;
      font-size: 14px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      line-height: 1.2;
      gap: 2px;

      @include breakpoint(ipad) {
        font-size: 12px;
      }

      svg {
        color: #fe6f0e;
        font-size: 19px;
        position: relative;
        left: -2px;
        top: -1px;

        @include breakpoint(ipad) {
          font-size: 16px;
        }
      }
    }

    h3 {
      color: #121212;
      font-size: 16px;
      font-weight: 700;
      transition: $transition;
      @include content_limit(2);

      @include breakpoint(ipad) {
        font-size: 14px;
      }
    }
  }
}
